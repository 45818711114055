import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import CustomLink from '../../../common/CustomLink/CustomLink';
import Loader from '../../../common/Loader/Loader';
import useFetch from '../../../core/hooks/useFetch';
import './EmailConfirm.css';

function EmailConfirm() {
  const { hash } = useParams();
  const { doFetch, loading, result } = useFetch(
    `${process.env.REACT_APP_API_URL}/user/change-email/${hash}`,
    true,
  );

  useEffect(() => {
    doFetch({
      method: 'POST',
    });
  }, [doFetch]);

  return (
    <section className="email-confirm">
      <div className="wrap wrap--sm">
        <h1 className="email-confirm__title h2">Email changed</h1>

        {!result ? (
          !loading ? null : (
            <Loader open />
          )
        ) : (
          <h2 className="email-confirm__message h4">
            Your new email address is {result.data.email}
          </h2>
        )}

        <CustomLink
          link={{
            text: 'Back to home',
            href: '/',
            hasArrow: true,
            color: 'light',
            id: 'email-confirmed-home',
          }}
        />
      </div>
    </section>
  );
}

export default EmailConfirm;
