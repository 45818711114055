import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import CustomButton from '../../common/CustomButton/CustomButton';
import CustomForm from '../../common/CustomForm/CustomForm';
import CustomInput from '../../common/CustomInput/CustomInput';
import { Icon } from '../../common/Icon/Icon';
import { useAlert } from '../../core/context/alert.context';
import { useDataContext } from '../../core/context/data.context';
import { addBackground, isValidEmail } from '../../core/helpers';
import useFetch from '../../core/hooks/useFetch';
import { useFormInput } from '../../core/hooks/useFormInput';
import useModal from '../../core/hooks/useModal';
import '../Login/Login.css';

const BASE_URL = process.env.REACT_APP_API_URL;

function ForgotPassword() {
  const { modal } = useModal({ keepOpen: true });
  const { setAlert } = useAlert();
  const email = useFormInput('', (value) => isValidEmail(value));
  const { result, doFetch, loading } = useFetch(`${BASE_URL}/user/send-reset-password-link`);
  const {
    data: { generalData },
    setPage,
  } = useDataContext();

  const handleSubmit = useCallback(() => {
    doFetch({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email.value,
      }),
    });
  }, [doFetch, email.value]);

  useEffect(() => {
    if (result?.status !== 'success') return;
    setAlert({
      message: result.message,
    });
  }, [result, setAlert]);

  useEffect(() => {
    setPage('');
  }, [setPage]);

  return (
    <>
      <Helmet>
        {!generalData?.seo?.title ? null : <title>{generalData?.seo?.title}</title>}
        {!generalData?.seo?.description ? null : (
          <meta name="description" content={generalData?.seo?.description} />
        )}
      </Helmet>
      <section className="login" {...addBackground({ img: generalData.img })}>
        {modal({
          children: (
            <div className="login__container flex gap-xs">
              <Link className="modal__close" to="/">
                <Icon.Plus className="icon-large" />
                <span className="sr-only">Home page</span>
              </Link>
              <h1 className="login__title h4">
                Enter your email and we will send you a password reset link
              </h1>
              <CustomForm
                className="flex flex--center gap-sm"
                inputs={[email]}
                onSubmit={handleSubmit}
              >
                <CustomInput
                  id="email"
                  type="text"
                  name="email"
                  placeholder="Enter your email address"
                  label="Email address"
                  input={email}
                  emptyMessage="Please fill in your email"
                  invalidMessage="Please fill in a valid email address"
                />
                <CustomButton
                  className="login-form__cta btn btn--dark"
                  type="submit"
                  loading={loading}
                >
                  Send reset password link
                </CustomButton>
                <Link className="link" to="/login">
                  Log in
                </Link>
              </CustomForm>
            </div>
          ),
          keepMounted: true,
        })}
      </section>
    </>
  );
}

export default ForgotPassword;
