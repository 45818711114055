import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CustomButton from '../../common/CustomButton/CustomButton';
import CustomForm from '../../common/CustomForm/CustomForm';
import CustomInput from '../../common/CustomInput/CustomInput';
import { useAlert } from '../../core/context/alert.context';
import { isEmpty, isValidPassword } from '../../core/helpers';
import useFetch from '../../core/hooks/useFetch';
import { useFormInput } from '../../core/hooks/useFormInput';

const BASE_URL = process.env.REACT_APP_API_URL;

function ResetPasswordForm() {
  const navigate = useNavigate();
  const { setAlert } = useAlert();
  const [searchParams] = useSearchParams();
  const id = useMemo(() => searchParams.get('h') || '', [searchParams]);
  const password = useFormInput('', (value) => isValidPassword(value));
  const repeatPassword = useFormInput('', (value) => !isEmpty(value) && value === password.value);
  const { result, doFetch, loading } = useFetch(`${BASE_URL}/user/reset-password/${id}`);

  const handleSubmit = useCallback(() => {
    doFetch({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: password.value,
      }),
    });
  }, [doFetch, password.value]);

  useEffect(() => {
    if (!result || result.status !== 'success') return;
    setAlert({
      message: result.message,
    });
    navigate('/login');
  }, [navigate, result, setAlert]);

  if (!id) return null;

  return (
    <CustomForm
      className="user__form flex gap-sm"
      inputs={[password, repeatPassword]}
      onSubmit={handleSubmit}
    >
      <CustomInput
        id="password"
        type="password"
        name="password"
        placeholder="Enter your new password"
        label="New Password"
        input={password}
        emptyMessage="Please fill in your new password"
        invalidMessage="Please fill in a valid password, minimum eight characters, one uppercase letter, one lowercase letter, one digit and one special character."
      />
      <CustomInput
        id="repeat-new-password"
        type="password"
        name="repeat-new-password"
        placeholder="Repeat your new password"
        label="Repeat your new password"
        input={repeatPassword}
        emptyMessage="Please repeat your new password"
        invalidMessage="Your passwords don't match"
        autoComplete="off"
      />
      <CustomButton className="user__cta btn btn--dark" type="submit" loading={loading}>
        Save
      </CustomButton>
    </CustomForm>
  );
}

export default ResetPasswordForm;
