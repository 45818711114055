import { useEffect, useRef, useState } from 'react';

import { CustomRadioInteface } from '../models/inputs';

export const useFormRadio = (
  initialValue: string,
  validate: (newValue: string) => boolean = () => true,
): CustomRadioInteface => {
  const [value, setValue] = useState<string>(initialValue);
  const [isValid, setIsValid] = useState<boolean>(validate(value));
  const [showError, setShowError] = useState<boolean>(false);
  const ref = useRef(null);

  useEffect(() => {
    setValue(initialValue);
    setShowError(false);
    setIsValid(validate(initialValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowError(false);
    const newValue = e.target.value;
    setValue(newValue);
    setIsValid(validate(newValue));
  };

  return { value, onChange, isValid, showError, setValue, ref, setShowError };
};
