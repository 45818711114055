import { useCallback, useMemo, useRef } from 'react';

import CustomLink from '../../common/CustomLink/CustomLink';
import { Icon } from '../../common/Icon/Icon';
import { TBackground, addBackground } from '../../core/helpers';
import useModal from '../../core/hooks/useModal';
import { ILink } from '../../core/models';
import HubSpotForm from '../HubSpotForm/HubSpotForm';
import './Result.css';

interface IProps {
  title: string;
  desc?: string;
  cta?: ILink;
  form?: {
    portalId?: string;
    formId?: string;
    region?: string;
    id?: string;
    btnLabel?: string;
  };
  resultItems: {
    title: string;
    desc?: string;
  }[];
  background?: TBackground;
  slug: string;
}

function Result({ title, desc, cta, form, resultItems, background, slug }: IProps) {
  const { modal, open, close } = useModal({});
  const btnRef = useRef(null);

  const openModal = useCallback(() => {
    if (!btnRef.current) return;
    open(btnRef.current);
  }, [open]);

  const items = useMemo(
    () =>
      resultItems?.map((item) => (
        <div className="result__item blur flex gap-sm px-sm py-sm" key={item.title}>
          <h3 className="result__item-title h3">{item.title}</h3>
          <p className="result__item-desc body-parge">{item.desc}</p>
        </div>
      )) || [],
    [resultItems],
  );

  return (
    <section
      className={`result${background ? ' section-bg' : ' section'}`}
      {...addBackground({ img: undefined, gradient: background })}
    >
      <div className="wrap wrap--lg flex gap-lg">
        <div className="result__content gap-lg">
          <h2 className="result__title h2">{title}</h2>
          <div className="result__info flex gap-md">
            {!desc ? null : <p className="result__desc body-large">{desc}</p>}
            {!cta ? null : <CustomLink className="result__cta" link={cta} />}
            {!form?.btnLabel ||
            !form?.portalId ||
            !form?.formId ||
            !form?.region ||
            !form?.id ? null : (
              <>
                <button
                  className="programs__item-cta btn btn--secondary"
                  type="button"
                  onClick={openModal}
                  ref={btnRef}
                  id={`${slug}-${form.formId}`}
                >
                  {form.btnLabel}
                </button>
                {modal({
                  children: (
                    <>
                      <button className="modal__close" onClick={close} type="button">
                        <Icon.Plus className="icon-large" />
                        <span className="sr-only">Close modal</span>
                      </button>
                      <HubSpotForm
                        portalId={form.portalId}
                        formId={form.formId}
                        region={form.region}
                        id={form.id}
                      />
                    </>
                  ),
                  keepMounted: true,
                })}
              </>
            )}
          </div>
        </div>
        <div className="result__container gap-md">{items}</div>
      </div>
    </section>
  );
}

export default Result;
