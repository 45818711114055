import { useCallback, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import countries from '../../../assets/country-list.json';
import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomCheckbox from '../../../common/CustomCheckbox/CustomCheckbox';
import CustomForm from '../../../common/CustomForm/CustomForm';
import CustomInput from '../../../common/CustomInput/CustomInput';
import CustomSelect from '../../../common/CustomSelect/CustomSelect';
import Message from '../../../common/Message/Message';
import { PASSWORD_REGEX } from '../../../core/constants';
import { isEmpty } from '../../../core/helpers';
import useFetch from '../../../core/hooks/useFetch';
import { useFormCheckbox } from '../../../core/hooks/useFormCheckbox';
import { useFormInput } from '../../../core/hooks/useFormInput';

const COUNTRY_LIST = countries.map((el: { code: string; name: string }) => ({
  value: el.code,
  label: el.name,
}));

function RegisterForm() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const hash = searchParams.get('h');

  const {
    doFetch: checkInvitationLink,
    result: data,
    error: invitationError,
  } = useFetch(`${process.env.REACT_APP_API_URL}/user/invited-email/${hash}`);
  const {
    result: registerResult,
    doFetch: registerUser,
    loading,
  } = useFetch(`${process.env.REACT_APP_API_URL}/user/register`, true);

  const password = useFormInput('', (value) => !isEmpty(value) && PASSWORD_REGEX.test(value));
  const repeatPassword = useFormInput('', (value) => !isEmpty(value) && value === password.value);
  const email = useFormInput(data?.data.email || '', (value) => !isEmpty(value));
  const termsCheckbox = useFormCheckbox(false, (value) => value);
  const country = useFormInput(data?.data.country || '', (value) => !isEmpty(value));

  const handleSubmit = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const body: any = {
      email: email.value,
      password: password.value,
      country_code: country.value,
      accepted_conditions: termsCheckbox.checked,
    };

    if (data?.data._id) {
      body.connected_id = data.data._id;
    }

    await registerUser({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  }, [
    country.value,
    data?.data._id,
    email.value,
    password.value,
    registerUser,
    termsCheckbox.checked,
  ]);

  useEffect(() => {
    checkInvitationLink({
      method: 'GET',
    });
  }, [checkInvitationLink]);

  useEffect(() => {
    if (!registerResult) return;
    navigate('/success');
  }, [navigate, registerResult]);

  return (
    <section className="user__form">
      <CustomForm
        className="flex gap-xs"
        inputs={[password, repeatPassword, country, termsCheckbox]}
        onSubmit={handleSubmit}
      >
        <>
          {invitationError && <Message status="error" text={invitationError.message || ''} />}
          <CustomInput
            type="email"
            id="email"
            name="email"
            input={email}
            placeholder="Your email"
            label="Your email"
            emptyMessage="Please fill in your email"
            invalidMessage="Please fill in a valid email address"
            disabled
          />
          <CustomInput
            type="password"
            id="password"
            name="password"
            input={password}
            placeholder="Your password"
            label="Your password"
            emptyMessage="Password is required"
            invalidMessage="Please fill in a valid password, minimum eight characters, one uppercase letter, one lowercase letter, one digit and one special character."
            autoComplete="off"
          />
          <CustomInput
            type="password"
            id="password-repeat"
            name="password-repeat"
            input={repeatPassword}
            placeholder="Repeat your password"
            label="Repeat your password"
            emptyMessage="It's required to repeat your password"
            invalidMessage="Your passwords don't match"
            autoComplete="off"
          />
          <CustomSelect
            id="country"
            name="country"
            placeholder="Country"
            label="Country"
            options={COUNTRY_LIST}
            input={country}
            emptyMessage="Country is required"
          />
          <CustomCheckbox
            id="terms-checkbox"
            name="terms-checkbox"
            input={termsCheckbox}
            label={
              <>
                I agree with the{' '}
                <Link to="/privacy-policy" target="_blank">
                  Privacy Statement
                </Link>{' '}
                and{' '}
                <Link to="/terms-of-use" target="_blank">
                  Terms of Use.
                </Link>
              </>
            }
            invalidMessage="You have to accept the privacy policy and terms of Use"
          />
          <CustomButton type="submit" className="btn" disabled={invitationError} loading={loading}>
            Create account
          </CustomButton>
        </>
      </CustomForm>
    </section>
  );
}

export default RegisterForm;
