import grain from '../assets/grain.png';
import { IImage } from './models';

/* eslint-disable @typescript-eslint/no-explicit-any */
export function debounce<F extends (...args: Parameters<F>) => ReturnType<F>>(
  func: F,
  waitFor: number,
): (...args: Parameters<F>) => void {
  let timeout: NodeJS.Timeout;
  return (...args: Parameters<F>): void => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), waitFor);
  };
}

export function isEmpty(value: string) {
  return value.trim() === '';
}

export function isValidEmail(email: string) {
  const regex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  return !!(email && regex.test(email));
}

export function isValidPassword(password: string) {
  // TODO: Change if needed
  return !!(password.trim().length >= 8);
}

export function slugify(str: string) {
  return str
    .toString()
    .normalize('NFKD')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/_/g, '-')
    .replace(/--+/g, '-')
    .replace(/-$/g, '');
}

export function buildParams(data: object) {
  const params = new URLSearchParams();

  Object.entries(data).forEach(([key, value]) => {
    if (!value) return;
    if (Array.isArray(value)) {
      value.forEach((el) => params.append(key, el.toString()));
    } else {
      params.append(key, value.toString());
    }
  });

  return params.toString();
}

export function range(start: number, end: number) {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
}

export const toBool = (str?: string): boolean => {
  if (str === undefined) str = '';
  str = str.toString();
  switch (str.toLowerCase()) {
    case 'false':
    case 'no':
    case '0':
    case '': {
      return false;
    }

    default: {
      return true;
    }
  }
};

export function mailTo(email: string, subject?: string, body?: string) {
  let url;
  url = `mailto:${email}`;
  if (subject) url += `?subject=${subject}`;
  if (body) url += `&body=${body}`;
  window.open(url);
}

export function chunkIntoN(arr: any[], n: number) {
  const result = [];
  for (let i = n; i > 0; i -= 1) {
    result.push(arr.splice(0, Math.ceil(arr.length / i)));
  }
  return result;
}

export function hasRoom(
  container: HTMLButtonElement | null,
  element: HTMLDivElement | null,
  center?: boolean,
) {
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  return (
    vw >
    (container?.getBoundingClientRect().left || 0) +
      (center ? (container?.offsetWidth || 0) / 2 : 0) +
      (element?.offsetWidth || 0) / (center ? 2 : 1) +
      24
  );
}

export function shuffle(array: any) {
  let m = array.length;
  let t;
  let i;

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(Math.random() * (m -= 1));

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}

export function generateUEID() {
  let first = `${Math.random() * 46656 || 0}`;
  let second = `${Math.random() * 46656 || 0}`;
  first = `000${first.toString()}`.slice(-3);
  second = `000${second.toString()}`.slice(-3);

  return first + second;
}

const gradients = {
  red: '--red-gradient',
  red2: '--red2-gradient',
  green: '--green-gradient',
  blue5: '--blue5-gradient',
  blue4: '--blue4-gradient',
  blue3: '--blue3-gradient',
  blue_orange: '--blue-orange-gradient',
  red_blue: '--red-blue-gradient',
};

export type TBackground = keyof typeof gradients;

export function addBackground({ img, gradient }: { img?: IImage; gradient?: TBackground }) {
  return {
    style: {
      backgroundImage: img
        ? `url(${grain}), url(${process.env.REACT_APP_API_URL}/uploads/${img.sizes?.large?.uri || img.uri})`
        : gradient
          ? `url(${grain}), var(${gradients[gradient]})`
          : ``,
      backgroundBlendMode: 'soft-light, normal',
      backgroundSize: 'auto, cover',
      backgroundPosition: 'center',
    },
  };
}

export function mergeRefs(...refs: any) {
  return (node: any) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const ref of refs) {
      if (!ref) return;
      ref.current = node;
    }
  };
}

export function capitalize(string: string): string {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
