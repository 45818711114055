/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { AUTH_TOKEN } from '../constants';
// eslint-disable-next-line import/no-cycle
import useFetch from '../hooks/useFetch';
import { CurrentUser } from '../models';
import { CustomCheckboxInteface, CustomInputInteface } from '../models/inputs';
import { useAlert } from './alert.context';

interface UserInterface {
  user: CurrentUser;
  loginLoading: boolean;
  loginError: any;
  setUser: any;
  login: (
    email: CustomInputInteface,
    password: CustomInputInteface,
    remember: CustomCheckboxInteface,
  ) => Promise<void>;
  logout: () => void;
  forceUpdate: () => void;
  updateToken: (token: string) => void;
}

const UserContext = createContext<UserInterface | null>(null);

export const useUserContext = () => useContext(UserContext) as UserInterface;

interface Props {
  children: JSX.Element;
}

export function UserContextProvider({ children }: Props) {
  const navigate = useNavigate();
  const {
    loading: loginLoading,
    doFetch: doLoginFetch,
    error: loginError,
  } = useFetch(`${process.env.REACT_APP_API_URL}/user/login`);
  const {
    doFetch: doUserFetch,
    error: userError,
    abortController,
  } = useFetch(
    `${process.env.REACT_APP_API_URL}/user?populate=profile_pic&populate=expertises&populate=member_classes&populate=industries&populate=community_roles&populate=subscriptions`,
  );
  const {
    doFetch: doPgUserFetch,
    error: pgUserError,
    abortController: pgAbortController,
  } = useFetch(`${process.env.REACT_APP_PG_SERVER_HOST}/userprofilepage`);
  const [authToken, setAuthToken] = useState(() => {
    const localToken = localStorage.getItem(AUTH_TOKEN);
    const sessionToken = sessionStorage.getItem(AUTH_TOKEN);

    if (!localToken && !sessionToken) return '';
    if (localToken) return JSON.parse(localToken);
    if (sessionToken) return JSON.parse(sessionToken);
  });
  const [user, setUser] = useState<CurrentUser>({
    email: '',
    token: authToken,
    role: 0,
    _id: '',
    subscriptions: [],
  });

  const { setAlert } = useAlert();
  const [fromLogin, setFromLogin] = useState(false);
  const [update, forceUpdate] = useReducer((x) => x + 1, 0);

  const setSessionStorageToken = useCallback((token: string) => {
    sessionStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
    setAuthToken(token);
  }, []);

  const setLocalSessionToken = useCallback((token: string) => {
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
    setAuthToken(token);
  }, []);

  const removeAuthToken = useCallback(() => {
    sessionStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(AUTH_TOKEN);
    setAuthToken('');
    setUser({
      email: '',
      token: '',
      role: 0,
      _id: '',
      subscriptions: [],
    });
    navigate('/');
  }, [navigate]);

  const updateToken = useCallback(
    (token: string) => {
      const localToken = localStorage.getItem(AUTH_TOKEN);
      const sessionToken = sessionStorage.getItem(AUTH_TOKEN);

      if (!localToken && !sessionToken) return;
      if (localToken) return setLocalSessionToken(token);
      if (sessionToken) return setSessionStorageToken(token);
    },
    [setLocalSessionToken, setSessionStorageToken],
  );

  useEffect(() => {
    if (!authToken || fromLogin) return;
    (async () => {
      const res = await Promise.all([
        doUserFetch({
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }),
        doPgUserFetch({
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }),
      ]);

      if (res[0] === 'aborted' || res[1] === 'aborted' || !res[0] || !res[1]) return;
      if (res[0]?.data && res[1]?.data) {
        setUser((prevState) => ({
          ...prevState,
          ...res[0].data,
          ...res[1].data,
          token: authToken,
        }));
      } else {
        removeAuthToken();
      }
    })();

    return () => {
      abortController.current?.abort();
      pgAbortController.current?.abort();
    };
  }, [
    abortController,
    authToken,
    doPgUserFetch,
    doUserFetch,
    pgAbortController,
    removeAuthToken,
    update,
    fromLogin,
  ]);

  const login = useCallback(
    async (
      email: CustomInputInteface,
      password: CustomInputInteface,
      remember: CustomCheckboxInteface,
    ) => {
      const res = await doLoginFetch({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email.value,
          password: password.value,
          source: 'WORLD',
        }),
      });
      if (res?.data.token) {
        setAlert({
          message: res.message,
        });
        setFromLogin(true);
        if (remember.checked) {
          setLocalSessionToken(res.data.token);
        } else {
          setSessionStorageToken(res.data.token);
        }
        setTimeout(() => {
          window.location.replace(`/pg${window.location.search}`);
        }, 0);
      }
    },
    [doLoginFetch, setAlert, setLocalSessionToken, setSessionStorageToken],
  );

  const logout = useCallback(async () => {
    if (authToken) {
      removeAuthToken();
      setAlert({
        message: 'Logged out.',
        status: 'warning',
      });
    }
  }, [authToken, removeAuthToken, setAlert]);

  useEffect(() => {
    if (!userError) return;
    abortController.current.abort();
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userError]);

  useEffect(() => {
    if (!pgUserError) return;
    abortController.current.abort();
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pgUserError]);

  useEffect(() => {
    const handleStorageUpdate = (e: any) => {
      const { key, newValue } = e;

      if (key !== AUTH_TOKEN) return;

      if (newValue) {
        setLocalSessionToken(newValue.replaceAll('"', ''));
      } else {
        removeAuthToken();
      }
    };

    window.addEventListener('storage', handleStorageUpdate);
    return () => {
      window.removeEventListener('storage', handleStorageUpdate);
    };
  }, [removeAuthToken, setLocalSessionToken]);

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      setUser,
      forceUpdate,
      loginLoading,
      loginError,
      userError,
      updateToken,
    }),
    [user, login, logout, loginLoading, loginError, userError, updateToken],
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
