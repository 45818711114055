import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import ApplyForm from '../../Components/User/ApplyForm/ApplyForm';
import CustomButton from '../../common/CustomButton/CustomButton';
import { Icon } from '../../common/Icon/Icon';
import { useDataContext } from '../../core/context/data.context';
import { useUserContext } from '../../core/context/user.context';
import { addBackground } from '../../core/helpers';
import useFetch from '../../core/hooks/useFetch';
import useModal from '../../core/hooks/useModal';
import '../Login/Login.css';

function Apply() {
  const [searchParams] = useSearchParams();
  const { modal } = useModal({ keepOpen: true });
  const { user } = useUserContext();
  const navigate = useNavigate();
  const {
    data: { generalData },
    setPage,
  } = useDataContext();
  const sentEmail = searchParams.get('email');
  const { doFetch, loading } = useFetch(`${process.env.REACT_APP_API_URL}/invitation/send`, true);

  useEffect(() => {
    if (user.email) navigate('/');
  }, [navigate, user]);

  useEffect(() => {
    setPage('');
  }, [setPage]);

  const resendLink = useCallback(() => {
    doFetch({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: sentEmail,
      }),
    });
  }, [doFetch, sentEmail]);

  return (
    <>
      <Helmet>
        {!generalData?.seo?.title ? null : <title>{generalData?.seo?.title}</title>}
        {!generalData?.seo?.description ? null : (
          <meta name="description" content={generalData?.seo?.description} />
        )}
      </Helmet>
      <div className="login" {...addBackground({ img: generalData.img })}>
        {modal({
          children: sentEmail ? (
            <div className="flex flex--center gap-md">
              <Link className="modal__close" to="/">
                <Icon.Plus className="icon-large" />
                <span className="sr-only">Home page</span>
              </Link>
              <h1 className="h2">Check your inbox</h1>
              <p>
                An email has been sent with a verification link to finish the creation of your
                account{' '}
              </p>
              <CustomButton className="link" type="button" onClick={resendLink} loading={loading}>
                Resend verification link
              </CustomButton>
            </div>
          ) : (
            <div className="login__container flex flex--center gap-xs">
              <ApplyForm />
            </div>
          ),
          keepMounted: true,
        })}
      </div>
    </>
  );
}

export default Apply;
