import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';

import RegisterForm from '../../Components/User/RegisterForm/RegisterForm';
import { Icon } from '../../common/Icon/Icon';
import { useDataContext } from '../../core/context/data.context';
import { useUserContext } from '../../core/context/user.context';
import { addBackground } from '../../core/helpers';
import useModal from '../../core/hooks/useModal';
import '../Login/Login.css';

function Register() {
  const { modal } = useModal({ keepOpen: true });
  const { user } = useUserContext();
  const navigate = useNavigate();
  const {
    data: { generalData },
    setPage,
  } = useDataContext();

  useEffect(() => {
    if (user.email) navigate('/');
  }, [navigate, user]);

  useEffect(() => {
    setPage('');
  }, [setPage]);

  return (
    <>
      <Helmet>
        {!generalData?.seo?.title ? null : <title>{generalData?.seo?.title}</title>}
        {!generalData?.seo?.description ? null : (
          <meta name="description" content={generalData?.seo?.description} />
        )}
      </Helmet>
      <div className="login" {...addBackground({ img: generalData.img })}>
        {modal({
          children: (
            <div className="login__container flex flex--center gap-xs">
              <Link className="modal__close" to="/">
                <Icon.Plus className="icon-large" />
                <span className="sr-only">Home page</span>
              </Link>
              <h1 className="login__title h2">Create account</h1>
              <RegisterForm />
            </div>
          ),
          keepMounted: true,
        })}
      </div>
    </>
  );
}

export default Register;
