import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomDropdown from '../../../common/CustomDropdown/CustomDropdown';
import { Icon } from '../../../common/Icon/Icon';
import { useSubscription } from '../../../core/context/subscription.context';
import { useUserContext } from '../../../core/context/user.context';
import { capitalize } from '../../../core/helpers';
import useFetch from '../../../core/hooks/useFetch';
import useModal from '../../../core/hooks/useModal';
import { ISubscription } from '../../../core/models';
import { IDiscount } from '../../../core/models/subscription';
import { getPriceString } from '../../../core/service';
import DiscountField from '../../DiscountField/DiscountField';

interface IProps {
  subscription: ISubscription;
}

function SubscriptionFull({ subscription }: IProps) {
  const discountCode = subscription.discountCode?.[0] || null;
  const { user } = useUserContext();
  const { cancelSubscription, updateSubscriptionLoading, renewSubscription, fetchSubscriptions } =
    useSubscription();
  const btnRef = useRef(null);
  const { modal, open, close } = useModal({});
  const [discount, setDiscount] = useState<IDiscount | null>(discountCode || null);
  const { result: applyDiscountResult, doFetch: doApplyDiscountFetch } = useFetch(
    `${process.env.REACT_APP_API_URL}/user/discounts/apply`,
  );
  const { result: removeDiscountResult, doFetch: doRemoveDiscountFetch } = useFetch(
    `${process.env.REACT_APP_API_URL}/user/discounts/remove`,
  );

  useEffect(() => {
    setDiscount(discountCode);
  }, [discountCode]);

  const applyDiscount = useCallback(
    (code: string) => {
      doApplyDiscountFetch({
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          code,
          subscription: subscription.subscription[0]._id,
        }),
      });
    },
    [doApplyDiscountFetch, subscription.subscription, user.token],
  );

  const removeDiscount = useCallback(() => {
    doRemoveDiscountFetch({
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        code: subscription.discountCode?.[0].code,
        subscription: subscription.subscription[0]._id,
      }),
    });
  }, [doRemoveDiscountFetch, subscription.discountCode, subscription.subscription, user.token]);

  useEffect(() => {
    if (!removeDiscountResult && !applyDiscountResult) return;
    fetchSubscriptions();
  }, [fetchSubscriptions, removeDiscountResult, applyDiscountResult]);

  return (
    <>
      <div className="subscription user__section" key={subscription.subscription[0].name}>
        <div className="subscription__header">
          <div className="subscription__left">
            <h3 className="subscription__title h4">{subscription.subscription[0].name}</h3>
            {!subscription.subscription[0].description ? null : (
              <p className="subscription__info">{subscription.subscription[0].description}</p>
            )}
          </div>
          <div className="subscription__right">
            {!subscription.status ? null : (
              <span
                className={`tag${subscription.status.message === 'Active' ? ' tag--success' : subscription.status.message === 'Pending' ? ' tag--warning' : ' tag--error'}`}
              >
                {subscription.status.message === 'Pending'
                  ? `Ending on ${moment(subscription.paymentDetails.endDate).format('MMM D YYYY')}`
                  : subscription.status.message}
                {subscription.status.message !== 'Cancelled' ? null : (
                  <CustomButton
                    type="button"
                    className="subscription__renew"
                    onClick={() => renewSubscription(subscription.subscription[0]._id)}
                    disabled={updateSubscriptionLoading}
                  >
                    Renew
                  </CustomButton>
                )}
                {subscription.status.message !== 'Pending' ? null : (
                  <Link
                    className="subscription__renew"
                    to={`/subscription/${subscription.subscription[0]._id}`}
                  >
                    Renew
                  </Link>
                )}
              </span>
            )}
            <div className="tag">
              {subscription.subscription[0].paid ? (
                !subscription.discountCode?.length ? (
                  `${getPriceString(subscription.subscription[0].paid_details.amount)} p/m`
                ) : (
                  <>
                    <span className="line-through">{`${getPriceString(
                      subscription.subscription[0].paid_details.amount,
                    )}`}</span>{' '}
                    {getPriceString(
                      subscription.subscription[0].paid_details.amount,
                      subscription.discountCode[0].discount_p,
                    )}{' '}
                    p/m
                  </>
                )
              ) : (
                'free'
              )}
            </div>
            {!subscription.subscription[0].paid ||
            (subscription.status?.message !== 'Active' &&
              subscription.status?.message !== 'Cancelled') ? null : (
              <CustomDropdown
                selected=""
                items={
                  subscription.status?.message === 'Active'
                    ? [{ value: 'stop', label: 'Stop subscription' }]
                    : [{ value: 'renew', label: 'Renew subscription' }]
                }
                handleChange={(e: React.MouseEvent<HTMLLIElement, MouseEvent>, val: string) => {
                  switch (val) {
                    case 'stop':
                      if (!btnRef.current) return;
                      open(btnRef.current);
                      break;
                    case 'renew':
                      renewSubscription(subscription.subscription[0]._id);
                      break;
                    default:
                      break;
                  }
                }}
                ref={btnRef}
              >
                <CustomButton type="button" className="subscription__options">
                  <Icon.Dots />
                  <span className="sr-only">Options</span>
                </CustomButton>
              </CustomDropdown>
            )}
            {!subscription.subscription[0].paid ? null : subscription.active ||
              subscription.status ? null : (
              <Link className="btn" to={`/subscription/${subscription.subscription[0]._id}`}>
                Buy
              </Link>
            )}
          </div>
        </div>
        {subscription.status?.message !== 'Pending' ? null : (
          <div className="subscription__warning tag tag--error">
            {`Payment failed.
            Please, retry payment with another method.
            Your subscription will stop at
          ${moment(subscription.paymentDetails.endDate).format('MMM D YYYY')}, if no payment method is provided.`}
          </div>
        )}

        {!subscription.paymentDetails ? null : (
          <>
            {subscription.status?.message === 'Payment pending' ? null : (
              <div className="subscription__details">
                <DiscountField
                  discount={discount}
                  subscription={subscription}
                  remove={removeDiscount}
                  apply={applyDiscount}
                />
              </div>
            )}
            <div className="subscription__details">
              {!subscription.paymentDetails?.lastPayment ? null : (
                <div className="subscription__detail">
                  <div>Last payment</div>
                  <div>{moment(subscription.paymentDetails.lastPayment).format('MMM D YYYY')}</div>
                </div>
              )}
              {!subscription.paymentDetails?.nextPayment ||
              subscription.status?.message === 'Cancelled' ? null : (
                <div className="subscription__detail">
                  <div>Next payment</div>
                  <div>
                    {getPriceString(
                      subscription.subscription[0].paid_details.amount,
                      subscription.discountCode?.[0]?.discount_p,
                    )}{' '}
                    on {moment(subscription.paymentDetails.nextPayment).format('MMM D YYYY')}
                  </div>
                </div>
              )}
              {!subscription.paymentDetails?.method ? null : (
                <div className="subscription__detail">
                  <div>Payment method</div>
                  <div>
                    {capitalize(subscription.paymentDetails.method.brand)}
                    {!subscription.paymentDetails.method.lastFour
                      ? ''
                      : ` ending in ${subscription.paymentDetails.method.lastFour}`}{' '}
                    {subscription.status?.message !== 'Active' ? null : (
                      <Link
                        className="underline"
                        to={`/subscription/${subscription.subscription[0]._id}`}
                      >
                        Change
                      </Link>
                    )}
                  </div>
                </div>
              )}
              {!subscription.paymentDetails?.nextPayment ? null : (
                <div className="subscription__detail">
                  <div>End date</div>
                  <div>
                    {moment(
                      subscription.status?.message === 'Pending'
                        ? subscription.paymentDetails.endDate
                        : subscription.paymentDetails.nextPayment,
                    ).format('MMM D YYYY')}
                  </div>
                </div>
              )}
              {subscription.subscription[0].paid || !subscription.expirationDate ? null : (
                <div className="subscription__detail">
                  <div>Expires on</div>
                  <div>{moment(subscription.expirationDate).format('MMM D YYYY')}</div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {!subscription.paymentDetails
        ? null
        : modal({
            children: (
              <div className="subscription__modal">
                <button className="modal__close" onClick={close} type="button">
                  <Icon.Plus className="icon-large" />
                  <span className="sr-only">Close modal</span>
                </button>
                <h2 className="h3">
                  Are you sure you want to want to stop your THNK World subscription?
                </h2>
                <h3 className="py-sm">
                  When stopped, your subscription remains active till{' '}
                  {moment(subscription.paymentDetails.endDate).format('MMM D YYYY')}. You can always
                  restart the subscription again.
                </h3>
                <div className="subscription__modal-footer">
                  <button className="btn" onClick={close} type="button">
                    No, keep subscription
                  </button>
                  <button
                    className="btn"
                    onClick={() => {
                      cancelSubscription(subscription.subscription[0]._id);
                      close();
                    }}
                    type="button"
                  >
                    Yes, stop subscription
                  </button>
                </div>
              </div>
            ),
          })}
    </>
  );
}

export default SubscriptionFull;
