import React, { useCallback, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

import CustomLink from '../../../common/CustomLink/CustomLink';
import { Icon } from '../../../common/Icon/Icon';
import useModal from '../../../core/hooks/useModal';
import { ILink } from '../../../core/models';
import HubSpotForm from '../../HubSpotForm/HubSpotForm';
import './AudienceItem.css';

export interface IAudienceItem {
  title: string;
  desc?: string;
  cta?: ILink;
  form: {
    portalId?: string;
    formId?: string;
    region?: string;
    id?: string;
    btnLabel?: string;
  };
  slug: string;
}

function AudienceItem({ title, desc, form, slug, cta }: IAudienceItem) {
  const { modal, open, close } = useModal({});
  const btnRef = useRef(null);

  const openModal = useCallback(() => {
    if (!btnRef.current) return;
    open(btnRef.current);
  }, [open]);

  return (
    <div className="audience__item" key={title}>
      <div className="audience__item-container flex gap-md">
        <h2 className="audience__item-title h2">
          <ReactMarkdown
            components={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              p: React.Fragment as any,
            }}
          >
            {title}
          </ReactMarkdown>
        </h2>
        {!desc ? null : <ReactMarkdown className="audience__item-desc">{desc}</ReactMarkdown>}
        <div className="audience__item-footer">
          {!form.btnLabel || !form.portalId || !form.formId || !form.region || !form.id ? null : (
            <>
              <button
                className="audience__item-cta btn"
                type="button"
                onClick={openModal}
                ref={btnRef}
                id={`${slug}-${form.formId}`}
              >
                {form.btnLabel}
              </button>
              {modal({
                children: (
                  <>
                    <button className="modal__close" onClick={close} type="button">
                      <Icon.Plus className="icon-large" />
                      <span className="sr-only">Close modal</span>
                    </button>
                    <HubSpotForm
                      portalId={form.portalId}
                      formId={form.formId}
                      region={form.region}
                      id={form.id}
                    />
                  </>
                ),
                keepMounted: true,
              })}
            </>
          )}
          {!cta ? null : <CustomLink link={cta} />}
        </div>
      </div>
    </div>
  );
}

export default AudienceItem;
