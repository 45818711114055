/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ReactMarkdown from 'react-markdown';

import CustomLink from '../../common/CustomLink/CustomLink';
import { Icon } from '../../common/Icon/Icon';
import { useUserContext } from '../../core/context/user.context';
import { TBackground, addBackground } from '../../core/helpers';
import { IImage } from '../../core/models';
import './HomeBanner.css';

interface IProps {
  title: string;
  ctaLabel?: string;
  desc?: string;
  img: IImage;
  background?: TBackground;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const em = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, ...rest } = props;
  return <span className="h1 accent">{children}</span>;
};

function HomeBanner({ title, ctaLabel, img, desc, background }: IProps) {
  const { user } = useUserContext();

  return (
    <section className="home-banner darken" {...addBackground({ img, gradient: background })}>
      <div className="wrap wrap--lg">
        <h1 className="home-banner__title h1">
          <ReactMarkdown
            components={{
              p: React.Fragment as any,
              em,
            }}
          >
            {title}
          </ReactMarkdown>
        </h1>
        {!desc ? null : <p className="home-banner__desc h4">{desc}</p>}
        {!ctaLabel ? null : user.email ? (
          <a
            href={`${process.env.REACT_APP_BASE_URL}/pg`}
            id="home-banner-playground-cta"
            className="home-banner__cta btn"
          >
            {ctaLabel}
            <Icon.Arrow className="icon" />
          </a>
        ) : (
          <CustomLink
            className="home-banner__cta"
            link={{
              href: '/login',
              text: ctaLabel,
              hasArrow: true,
              color: 'light',
              id: 'home-banner-login-link',
            }}
          />
        )}
      </div>
    </section>
  );
}

export default HomeBanner;
