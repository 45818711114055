import { useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import PageLoader from '../../common/PageLoader/PageLoader';
import TextBlock from '../../common/TextBlock/TextBlock';
import ToggleSelect from '../../common/ToggleSelect/ToggleSelect';
import { useDataContext } from '../../core/context/data.context';
import './Legal.css';

const TABS = [
  {
    name: 'Terms of Use',
    option: 'termsAndConditions',
  },
  {
    name: 'Privacy policy',
    option: 'privacyPolicy',
  },
];

function Legal({ type }: { type: string }) {
  const modalContainer: HTMLElement | null = document.getElementById('modal');
  const {
    data: {
      generalData: { legal },
    },
  } = useDataContext();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  const renewConsent = useCallback(() => {
    const hsp = window?._hsp || [];
    hsp.push(['showBanner']);
  }, []);

  // Temp solution
  useEffect(() => {
    if (!ref.current) return;
    const renewLink = ref.current.querySelector('a[href="#renew"]');
    if (!renewLink) return;
    renewLink.addEventListener('click', (e) => {
      e.preventDefault();
      renewConsent();
    });
  }, [legal, renewConsent, type]);

  const handleTabChange = useCallback(
    (e: string) => {
      navigate(
        e === TABS[0].option ? '/terms-of-use' : e === TABS[1].option ? '/privacy-policy' : '/',
      );
    },
    [navigate],
  );

  return (
    <>
      <Helmet>
        {!legal[`${type}Seo`]?.title ? null : <title>{legal[`${type}Seo`]?.title}</title>}
        {!legal[`${type}Seo`]?.description ? null : (
          <meta name="description" content={legal[`${type}Seo`]?.description} />
        )}
      </Helmet>
      {!modalContainer ? null : createPortal(<PageLoader open={!legal[type]} />, modalContainer)}
      <section className="legal" ref={ref}>
        <div className="wrap">
          <div className="legal__header">
            <ToggleSelect
              selected={type === TABS[0].option ? 0 : type === TABS[1].option ? 1 : 0}
              items={TABS}
              onChange={handleTabChange}
            />
          </div>
        </div>
        <TextBlock content={legal[type]} />
      </section>
    </>
  );
}

export default Legal;
