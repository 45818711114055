import { useCallback } from 'react';

import countries from '../../../assets/country-list.json';
import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomForm from '../../../common/CustomForm/CustomForm';
import CustomInput from '../../../common/CustomInput/CustomInput';
import CustomTextarea from '../../../common/CustomTextarea/CustomTextarea';
import { useUserContext } from '../../../core/context/user.context';
import { isEmpty, isValidEmail } from '../../../core/helpers';
import useFetch from '../../../core/hooks/useFetch';
import { useFormInput } from '../../../core/hooks/useFormInput';

function ProfileForm() {
  const { user, forceUpdate } = useUserContext();
  const { doFetch: updateUser, loading } = useFetch(
    `${process.env.REACT_APP_PG_SERVER_HOST}/userprofilepage`,
    true,
  );

  const firstName = useFormInput(user?.firstname || '', (value) => !isEmpty(value));
  const lastName = useFormInput(user?.lastname || '', (value) => !isEmpty(value));
  const email = useFormInput(user?.email || '', (value) => isValidEmail(value));
  const poem = useFormInput(user?.antiProfileText || '', () => true);
  const country = useFormInput(
    countries.find((el: { code: string; name: string }) => el.code === user?.country_code)?.name ||
      '',
  );

  const onSubmit = useCallback(async () => {
    const body = {
      firstname: firstName.value,
      lastname: lastName.value,
      antiProfileText: poem.value,
    };

    await updateUser({
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    forceUpdate();
  }, [firstName.value, forceUpdate, lastName.value, poem.value, updateUser, user.token]);

  return (
    <CustomForm className="form" inputs={[firstName, lastName]} onSubmit={onSubmit}>
      <div className="form__group">
        <CustomInput
          id="firstName"
          placeholder="First name"
          name="firstName"
          type="text"
          input={firstName}
          label="First name"
          emptyMessage="Please fill in your first name"
        />
        <CustomInput
          id="lastName"
          placeholder="Last name"
          name="lastName"
          type="text"
          input={lastName}
          label="Last name"
          emptyMessage="Please fill in your last name"
        />
      </div>
      <CustomInput
        id="email"
        placeholder="Email"
        name="email"
        type="text"
        input={email}
        label="E-mail address"
        link={{
          text: 'Change',
          href: '#change-email',
          reloadDocument: true,
        }}
        disabled
      />
      <CustomInput
        id="country"
        placeholder="Country"
        name="country"
        type="text"
        input={country}
        disabled
        label="Country"
      />
      <CustomTextarea
        id="poem"
        placeholder="Your THNK poem"
        name="poem"
        input={poem}
        label="Poem"
        rows={7}
      />
      <CustomButton
        type="submit"
        className="user-edit-form__submit btn btn--primary"
        loading={loading}
      >
        Save changes
      </CustomButton>
    </CustomForm>
  );
}

export default ProfileForm;
