import { useRef } from 'react';
import { Link } from 'react-router-dom';

import { ILink } from '../../core/models/link';
import { Icon } from '../Icon/Icon';

interface IProps {
  link: ILink;
  className?: string;
}

function CustomLink({ link, className }: IProps) {
  const btnRef = useRef<HTMLAnchorElement>(null);

  return !link.text ? null : (
    <Link
      className={`${className ? `${className} ` : ''}${link.color === 'light' ? ` btn` : link.color === 'link' ? ' link' : ''}`}
      to={link.href}
      target={link.newTab ? '_blank' : ''}
      download={link.isDownload}
      id={link.id || ''}
      ref={btnRef}
    >
      {link.text}
      {!link.hasArrow ? null : <Icon.Arrow className="icon" />}
      {!link.isDownload ? null : <Icon.External className="icon" />}
    </Link>
  );
}

export default CustomLink;
