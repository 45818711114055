import { Navigate, Outlet } from 'react-router-dom';

import { CurrentUser } from '../../core/models';

interface Props {
  user: CurrentUser;
  redirectPath?: string;
  children?: JSX.Element;
}

function ProtectedRoute({ user, redirectPath, children }: Props) {
  if (!user.token) {
    return <Navigate to={redirectPath || '/'} replace />;
  }

  return children || <Outlet />;
}

export default ProtectedRoute;
