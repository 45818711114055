import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';

import LoginForm from '../../Components/User/LoginForm/LoginForm';
import { Icon } from '../../common/Icon/Icon';
import { useDataContext } from '../../core/context/data.context';
import { useUserContext } from '../../core/context/user.context';
import { addBackground, toBool } from '../../core/helpers';
import useModal from '../../core/hooks/useModal';
import './Login.css';

function Login() {
  const { modal } = useModal({ keepOpen: true });
  const { user } = useUserContext();
  const navigate = useNavigate();
  const {
    data: { generalData },
    setPage,
  } = useDataContext();

  useEffect(() => {
    if (user.email) navigate('/');
  }, [navigate, user]);

  useEffect(() => {
    setPage('');
  }, [setPage]);

  return (
    <>
      <Helmet>
        {!generalData?.seo?.title ? null : <title>{generalData?.seo?.title}</title>}
        {!generalData?.seo?.description ? null : (
          <meta name="description" content={generalData?.seo?.description} />
        )}
      </Helmet>
      <div className="login" {...addBackground({ img: generalData.img })} />
      {modal({
        children: (
          <>
            <Link className="modal__close" to="/">
              <Icon.Plus className="icon-large" />
              <span className="sr-only">Home page</span>
            </Link>
            <div className="login__container flex flex--center gap-xs">
              <h1 className="login__title h2">Log in</h1>
              <LoginForm />
              {toBool(process.env.REACT_APP_HIDE_REGISTRATION) ? null : (
                <Link className="link" to="/apply">
                  Create an account
                </Link>
              )}
            </div>
          </>
        ),
        keepMounted: true,
      })}
    </>
  );
}

export default Login;
