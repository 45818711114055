import { useMemo } from 'react';

import { CustomRadioInteface } from '../../core/models';

interface IProps {
  name: string;
  label?: React.ReactNode | string;
  input: CustomRadioInteface;
  invalidMessage?: string;
  className?: string;
  buttons: {
    value: string;
    label: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon: any;
  }[];
}

function CustomRadio({ name, label, input, invalidMessage, className = '', buttons }: IProps) {
  const error = useMemo(
    () => (!input.showError ? '' : !input.isValid ? 'invalid' : ''),
    [input.showError, input.isValid],
  );

  return (
    <div className={`input${!error ? '' : ' error'}${!className ? '' : ` ${className}`}`}>
      {!label ? null : (
        <div className="input__header input__header--radio">
          {!label ? null : <div className="h4">{label}</div>}
        </div>
      )}
      {buttons.map((btn) => (
        <div className="input__radio-wrap" key={btn.value}>
          <label
            className={`input__radio-label${input.value === btn.value ? ' checked' : ''}`}
            htmlFor={btn.value}
          >
            <input
              className="input__radio"
              type="radio"
              id={btn.value}
              name={name}
              value={btn.value}
              onChange={input.onChange}
              checked={input.value === btn.value}
            />
            <span
              className={`input__radio-checkmark${input.value === btn.value ? ' checked' : ''}`}
            />
            {btn.icon}
            {btn.label}
          </label>
        </div>
      ))}
      {!error ? null : (
        <div className="input__error">
          <span>{error === 'invalid' ? invalidMessage : ''}</span>
        </div>
      )}
    </div>
  );
}

export default CustomRadio;
