/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import { hasRoom, mergeRefs } from '../../core/helpers';
import './CustomDropdown.css';

interface IProps {
  children: JSX.Element;
  selected: string;
  items: any;
  handleChange: any;
}

const CustomDropdown = forwardRef<HTMLButtonElement, IProps>(
  ({ children, selected, items, handleChange }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const btnRef = useRef<HTMLButtonElement>(null);
    const menuRef = useRef(null);
    const itemsRef = useRef<HTMLLIElement[] | null[]>([]);
    const currentFocusIndex = useRef(0);

    const closeDropdownListener = useCallback(
      (e: any) => {
        if (btnRef.current !== e.target) {
          setIsOpen(false);
          document.removeEventListener('click', closeDropdownListener);
        }
      },
      [btnRef],
    );

    const closeDropdown = useCallback(() => {
      setIsOpen(false);
      if (btnRef.current) btnRef.current.focus();
      document.removeEventListener('click', closeDropdownListener);
    }, [closeDropdownListener]);

    const moveFocus = useCallback((index: any) => {
      currentFocusIndex.current = index;
      itemsRef.current[index]?.focus();
    }, []);

    useEffect(() => {
      if (isOpen) moveFocus(0);
    }, [isOpen, moveFocus]);

    const handleClick = useCallback(() => {
      if (isOpen) {
        closeDropdown();
      } else {
        setIsOpen((prevState) => !prevState);
      }
      document.addEventListener('click', closeDropdownListener);
    }, [closeDropdown, closeDropdownListener, isOpen]);

    const handleBtnKeyDown = useCallback(
      (e: any) => {
        if (e.keyCode === 9 || (e.shiftKey && e.keyCode === 9)) {
          closeDropdown();
        }
      },
      [closeDropdown],
    );

    const handleItemKeyDown = useCallback(
      (e: any, value: any) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
          e.preventDefault();
          if (handleChange) handleChange(e, value);
          closeDropdown();
        }

        if (e.keyCode === 38) {
          e.preventDefault();
          if (currentFocusIndex.current > 0) moveFocus(currentFocusIndex.current - 1);
          else moveFocus(itemsRef.current.length - 1);
        }

        if (e.keyCode === 40) {
          e.preventDefault();
          if (currentFocusIndex.current < itemsRef.current.length - 1)
            moveFocus(currentFocusIndex.current + 1);
          else moveFocus(0);
        }

        if (e.keyCode === 9 || (e.shiftKey && e.keyCode === 9)) {
          closeDropdown();
        }
      },
      [handleChange, closeDropdown, moveFocus],
    );

    if (!items) return null;

    return (
      <div className="custom-dropdown">
        <children.type
          {...children.props}
          className={`${children.props.className} custom-dropdown__btn${isOpen ? ' open' : ''}`}
          aria-haspopup="true"
          aria-expanded={isOpen}
          onClick={handleClick}
          onKeyDown={handleBtnKeyDown}
          ref={mergeRefs(btnRef, ref)}
        />
        <ul
          ref={menuRef}
          className={`custom-dropdown__menu${isOpen ? ' custom-dropdown__menu--visible' : ''}`}
          role="menu"
          style={hasRoom(btnRef.current, menuRef.current) ? { left: 0 } : { right: 0 }}
        >
          {items.map((item: any, i: any) => (
            <li
              className={`custom-dropdown__item${
                item.value === selected ? ' custom-dropdown__item--active' : ''
              }`}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              role="menuitem"
              tabIndex={-1}
              ref={(itemRef) => {
                itemsRef.current[i] = itemRef;
              }}
              onClick={
                handleChange
                  ? (e) => {
                      handleChange(e, item.value);
                      setTimeout(() => {
                        if (btnRef.current) btnRef.current.focus();
                      }, 0);
                    }
                  : () => {}
              }
              onKeyDown={(e) => handleItemKeyDown(e, item.value)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    );
  },
);

export default CustomDropdown;
