import './Testimonials.css';

interface IProps {
  title: string;
  items: {
    title: string;
    desc?: string;
  }[];
}

function Testimonials({ title, items }: IProps) {
  return (
    <section className="testimonials">
      <div className="wrap wrap--lg">
        <h2 className="h4">{title}</h2>
        {!items.length ? null : (
          <ul className="testimonials__container">
            {items.map((testimonial) => (
              <li className="testimonials__item" key={testimonial.title}>
                <blockquote className="testimonials__quote">
                  <h3 className="h3">{testimonial.title}</h3>
                  <p className="testimonials__item-desc">{testimonial.desc}</p>
                </blockquote>
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  );
}

export default Testimonials;
