import { Link } from 'react-router-dom';

import { useDataContext } from '../../core/context/data.context';
import { IFooter } from '../../core/models';
import './PlaygroundFooter.css';

function PlaygroundFooter() {
  const {
    data: {
      generalData: { footer },
    },
  }: { data: { generalData: { footer: IFooter } } } = useDataContext();

  if (!footer) return null;

  return (
    <footer className="pg-footer">
      <div className="wrap wrap--xl">
        <nav className="pg-footer__nav">
          <ul className="pg-footer__list">
            {footer.navigation?.map((el) => (
              <li className="pg-footer__item" key={el.label}>
                <Link className="pg-footer__link link" to={el.href}>
                  {el.label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </footer>
  );
}

export default PlaygroundFooter;
