/* eslint-disable jsx-a11y/control-has-associated-label */
import moment from 'moment';
import { useCallback, useEffect, useRef } from 'react';

import SubscriptionFull from '../../Components/User/SubscriptionFull/SubscriptionFull';
import CustomButton from '../../common/CustomButton/CustomButton';
import { Icon } from '../../common/Icon/Icon';
import Loader from '../../common/Loader/Loader';
import { useDataContext } from '../../core/context/data.context';
import { useSubscription } from '../../core/context/subscription.context';
import { useUserContext } from '../../core/context/user.context';
import { capitalize, toBool } from '../../core/helpers';
import useFetch from '../../core/hooks/useFetch';
import useModal from '../../core/hooks/useModal';
import { IPaymentHistory } from '../../core/models/subscription';
import '../../style/user.css';

const HIDE_DOWNLOAD = false;
const BASE_URL = process.env.REACT_APP_API_URL;

function Subscriptions() {
  const { modal, open, close } = useModal({});
  const { user } = useUserContext();
  const { setPage } = useDataContext();
  const { fetchSubscriptions, updateSubscriptionResult, updateSubscriptionLoading } =
    useSubscription();
  const historyBtnRef = useRef<HTMLButtonElement>(null);
  const { doFetch } = useFetch('');

  const {
    doFetch: doHistoryFetch,
    loading: historyLoading,
    result: history,
  } = useFetch(`${process.env.REACT_APP_API_URL}/user/payment-history`);

  useEffect(() => {
    setPage('');
  }, [setPage]);

  useEffect(() => {
    if (!user.email) return;

    fetchSubscriptions();
  }, [user.email, fetchSubscriptions]);

  useEffect(() => {
    if (!updateSubscriptionResult) return;

    fetchSubscriptions();
  }, [updateSubscriptionResult, fetchSubscriptions]);

  const showPaymentHistory = useCallback(() => {
    if (!historyBtnRef.current) return;
    doHistoryFetch({
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    open(historyBtnRef.current);
  }, [doHistoryFetch, open, user.token]);

  const downloadInvoice = useCallback(
    async (item: IPaymentHistory) => {
      const blob = await doFetch({
        newURL: `${BASE_URL}/payment-history/export-invoice/${item._id}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const url = URL.createObjectURL(blob);
      const tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute(
        'download',
        `invoice_${item.subscription}_${new Date(item.date).toLocaleDateString()}.pdf`.replace(
          ' ',
          '',
        ),
      );
      tempLink.click();
      tempLink.remove();
    },
    [doFetch, user.token],
  );

  return (
    <section className="user">
      <div className="wrap wrap--md">
        <div className="user__header">
          <h1 className="user__title h3">Manage subscriptions</h1>
          {toBool(process.env.REACT_APP_HIDE_PAYMENT_HISTORY) ? null : (
            <CustomButton
              className="link"
              type="button"
              onClick={showPaymentHistory}
              ref={historyBtnRef}
            >
              Payment history
            </CustomButton>
          )}
        </div>
        {!user.subscriptions?.length ? (
          <h2 className="subscription__info h4">You don&apos;t have any subscriptions</h2>
        ) : (
          <ul>
            {user.subscriptions.map((subscription) =>
              !subscription.subscription.length ? null : (
                <SubscriptionFull
                  subscription={subscription}
                  key={subscription.subscription[0].name}
                />
              ),
            )}
          </ul>
        )}
        <Loader open={updateSubscriptionLoading} />
      </div>
      {modal({
        size: ' lg',
        children: (
          <div className="payment-history">
            <button className="modal__close" onClick={close} type="button">
              <Icon.Plus className="icon-large" />
              <span className="sr-only">Close modal</span>
            </button>
            <h2 className="h2">Payment history</h2>
            {!historyLoading ? null : <span className="loader" />}
            <div className="table-container">
              <table className="payment-history__table">
                <tbody>
                  <tr>
                    <th>Date</th>
                    <th>Subscription</th>
                    <th>Amount</th>
                    <th>Payment status</th>
                    {HIDE_DOWNLOAD ? null : <th />}
                  </tr>
                  {history?.data
                    .sort(
                      (a: IPaymentHistory, b: IPaymentHistory) =>
                        moment(b.date).unix() - moment(a.date).unix(),
                    )
                    .map((item: IPaymentHistory) => (
                      <tr key={item._id}>
                        <td>{moment(item.date).format('MMM D YYYY')}</td>
                        <td>{item.subscription}</td>
                        <td>
                          <span className="tag">€ {item.amount.toFixed(2)}</span>
                        </td>
                        <td>
                          <span className="tag tag--success">{capitalize(item.status)}</span>
                        </td>
                        {HIDE_DOWNLOAD ? null : (
                          <td align="right">
                            <CustomButton
                              className="download-btn"
                              type="button"
                              onClick={() => downloadInvoice(item)}
                            >
                              <Icon.Download />
                              <span className="sr-only">Downalod invoice</span>
                            </CustomButton>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        ),
      })}
    </section>
  );
}

export default Subscriptions;
