import moment from 'moment';
import { Link } from 'react-router-dom';

import CustomButton from '../../../common/CustomButton/CustomButton';
import { useSubscription } from '../../../core/context/subscription.context';
import { ISubscription } from '../../../core/models';
import { getPriceString } from '../../../core/service';

interface IProps {
  subscription: ISubscription;
}

function SubscriptionSimple({ subscription }: IProps) {
  const { updateSubscriptionLoading, renewSubscription } = useSubscription();

  return (
    <div className="subscription user__section" key={subscription.subscription[0].name}>
      <div className="subscription__header">
        <div className="subscription__left">
          <h3 className="subscription__title h4">{subscription.subscription[0].name}</h3>
          {subscription.active ? (
            subscription.expirationDate && !subscription.subscription[0].paid ? (
              <div className="subscription__info">
                Expires on {moment(subscription.expirationDate).format('MMM D YYYY')}
              </div>
            ) : subscription.paymentDetails?.nextPayment && subscription.subscription[0].paid ? (
              <div className="subscription__info">
                Next payment is{' '}
                {getPriceString(
                  subscription.subscription[0].paid_details.amount,
                  subscription.discountCode?.[0]?.discount_p,
                )}{' '}
                on {moment(subscription.paymentDetails.nextPayment).format('MMM D YYYY')}
              </div>
            ) : null
          ) : !subscription.subscription[0].description ? null : (
            <p className="subscription__info">{subscription.subscription[0].description}</p>
          )}
        </div>
        <div className="subscription__right">
          {!subscription.status ? null : (
            <span
              className={`tag${subscription.status.message === 'Active' ? ' tag--success' : subscription.status.message === 'Pending' ? ' tag--warning' : ' tag--error'}`}
            >
              {subscription.status.message === 'Pending'
                ? `Ending on ${moment(subscription.paymentDetails.endDate).format('MMM D YYYY')}`
                : subscription.status.message}
              {subscription.status.message !== 'Cancelled' ? null : (
                <CustomButton
                  type="button"
                  className="subscription__renew"
                  onClick={() => renewSubscription(subscription.subscription[0]._id)}
                  disabled={updateSubscriptionLoading}
                >
                  Renew
                </CustomButton>
              )}
              {subscription.status.message !== 'Pending' ? null : (
                <Link
                  className="subscription__renew"
                  to={`/subscription/${subscription.subscription[0]._id}`}
                >
                  Renew
                </Link>
              )}
            </span>
          )}
          <div className="tag">
            {subscription.subscription[0].paid ? (
              !subscription.discountCode?.length ? (
                `${getPriceString(subscription.subscription[0].paid_details.amount)} p/m`
              ) : (
                <>
                  <span className="line-through">{`${getPriceString(
                    subscription.subscription[0].paid_details.amount,
                  )}`}</span>{' '}
                  {getPriceString(
                    subscription.subscription[0].paid_details.amount,
                    subscription.discountCode[0].discount_p,
                  )}{' '}
                  p/m
                </>
              )
            ) : (
              'free'
            )}
          </div>
          {!subscription.subscription[0].paid ? null : subscription.active ||
            subscription.status ? null : (
            <Link className="btn" to={`/subscription/${subscription.subscription[0]._id}`}>
              Buy
            </Link>
          )}
        </div>
      </div>
      {subscription.status?.message !== 'Pending' ? null : (
        <div className="subscription__warning tag tag--error">
          {`Payment failed. Please, retry payment with another method. Your subscription will stop at
          ${moment(subscription.paymentDetails.endDate).format('MMM D YYYY')}, if no payment method is provided.`}
        </div>
      )}
    </div>
  );
}

export default SubscriptionSimple;
