import { useEffect } from 'react';

import ChangeEmailForm from '../../Components/User/ChangeEmailForm/ChangeEmailForm';
import ChangePasswordForm from '../../Components/User/ChangePasswordForm/ChangePasswordForm';
import ProfileForm from '../../Components/User/ProfileForm/ProfileForm';
import { useDataContext } from '../../core/context/data.context';
import '../../style/user.css';

function Profile() {
  const { setPage } = useDataContext();

  useEffect(() => {
    setPage('');
  }, [setPage]);

  return (
    <section className="user">
      <div className="wrap wrap--md">
        <h1 className="h4">Manage profile</h1>
        <div className="user__section">
          <h2 className="user__section-title h6">Personal details</h2>
          <ProfileForm />
        </div>
        <div className="user__section">
          <h2 className="user__section-title h6">Change password</h2>
          <ChangePasswordForm />
        </div>
        <div className="user__section" id="change-email">
          <h2 className="user__section-title h6">Change E-mail</h2>
          <ChangeEmailForm />
        </div>
      </div>
    </section>
  );
}

export default Profile;
