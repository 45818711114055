import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomCheckbox from '../../../common/CustomCheckbox/CustomCheckbox';
import CustomForm from '../../../common/CustomForm/CustomForm';
import CustomInput from '../../../common/CustomInput/CustomInput';
import { PASSWORD_REGEX } from '../../../core/constants';
import { useUserContext } from '../../../core/context/user.context';
import { isEmpty, isValidEmail } from '../../../core/helpers';
import { useFormCheckbox } from '../../../core/hooks/useFormCheckbox';
import { useFormInput } from '../../../core/hooks/useFormInput';
import './LoginForm.css';

function LoginForm() {
  const [searchParams] = useSearchParams();
  const searchParamsEmail = searchParams.get('email');

  const email = useFormInput(
    searchParamsEmail ? decodeURIComponent(searchParamsEmail) : '',
    (value) => isValidEmail(value),
  );
  const password = useFormInput('', (value) => !isEmpty(value) && PASSWORD_REGEX.test(value));
  const remember = useFormCheckbox(false);
  const { login, loginLoading } = useUserContext();

  const handleSubmit = useCallback(() => {
    login(email, password, remember);
  }, [email, login, password, remember]);

  return (
    <section className="login-form">
      <CustomForm className="flex gap-xs" inputs={[email, password]} onSubmit={handleSubmit}>
        <>
          <CustomInput
            id="login-email"
            type="text"
            name="email"
            placeholder=""
            label="E-mail address"
            input={email}
            emptyMessage="Please fill in your email"
            invalidMessage="Please fill in a valid email address"
          />
          <CustomInput
            id="login-password"
            type="password"
            name="password"
            placeholder=""
            label="Password"
            input={password}
            link={{ text: 'Forgot your password?', href: '/forgot-password' }}
            emptyMessage="Please fill in your password"
            invalidMessage="Please fill in a valid password, minimum eight characters, one uppercase letter, one lowercase letter, one digit and one special character."
          />
          <CustomCheckbox id="remember" name="remember" label="Remember me" input={remember} />
          <CustomButton className="login-form__cta btn" type="submit" loading={loginLoading}>
            Log in
          </CustomButton>
        </>
      </CustomForm>
    </section>
  );
}

export default LoginForm;
