import CustomButton from '../../common/CustomButton/CustomButton';
import CustomInput from '../../common/CustomInput/CustomInput';
import { useFormInput } from '../../core/hooks/useFormInput';
import { ISubscription } from '../../core/models';
import { IDiscount } from '../../core/models/subscription';
import { getDiscountString } from '../../core/service';

interface IProps {
  subscription: ISubscription;
  discount: IDiscount | null;
  apply(value: string): void;
  remove(): void;
}

function DiscountField({ subscription, discount, apply, remove }: IProps) {
  const discountInput = useFormInput('');

  return (
    <div className="discount subscription__header">
      <div className="subscription__left">
        <div>Discount code</div>
        {!discount ? null : (
          <div>
            {discount.code}: {discount.name}
          </div>
        )}
      </div>
      <div className="subscription__right">
        {!discount ? (
          <div className="discount__field">
            <CustomInput
              input={discountInput}
              name="discount"
              id="discount"
              type="text"
              placeholder="Discount code"
            />
            <CustomButton className="btn" type="button" onClick={() => apply(discountInput.value)}>
              Apply
            </CustomButton>
          </div>
        ) : (
          <>
            <div>
              -{' '}
              {getDiscountString(
                subscription.subscription[0].paid_details.amount,
                discount.discount_p,
              )}
            </div>
            <CustomButton
              className="underline"
              type="button"
              onClick={() => {
                discountInput.setValue('');
                remove();
              }}
            >
              Remove
            </CustomButton>
          </>
        )}
      </div>
    </div>
  );
}

export default DiscountField;
