import { useMemo } from 'react';

import { addBackground } from '../../core/helpers';
import { IImage } from '../../core/models';
import './Audience.css';
import AudienceItem, { IAudienceItem } from './AudienceItem/AudienceItem';

interface IProps {
  img?: IImage;
  items: IAudienceItem[];
  slug: string;
}

function Audience({ img, items, slug }: IProps) {
  const cards = useMemo(
    () => items?.map((item) => <AudienceItem {...item} key={item.title} slug={slug} />),
    [items, slug],
  );

  return (
    <section className="audience" {...addBackground({ img, gradient: 'blue4' })}>
      <div className="wrap wrap--lg">
        <div className="audience__container">
          <div className="audience__items">{cards}</div>
        </div>
      </div>
    </section>
  );
}

export default Audience;
