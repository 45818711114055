import React, { useRef } from 'react';
import ReactMarkdown from 'react-markdown';

import { addBackground } from '../../core/helpers';
import useIntersection from '../../core/hooks/useIntersection';
import { IImage } from '../../core/models';
import './Commitment.css';

interface IProps {
  title: string;
  label?: string;
  img?: IImage;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const em = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, ...rest } = props;
  return <strong className="h1 accent">{children}</strong>;
};

function Commitment({ title, label, img }: IProps) {
  const titleRef = useRef(null);
  const { isIntersecting } = useIntersection(titleRef, 0.5);

  return (
    <section className="commitment" {...addBackground({ img, gradient: 'blue3' })}>
      <div className="wrap wrap--lg">
        {!label ? null : <div className="commitment__label overline">{label}</div>}
        <h2 className={`commitment__title h1${isIntersecting ? ' visible' : ''}`} ref={titleRef}>
          <ReactMarkdown
            components={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              p: React.Fragment as any,
              em,
            }}
          >
            {title}
          </ReactMarkdown>
        </h2>
      </div>
    </section>
  );
}

export default Commitment;
