import { useCallback } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomForm from '../../../common/CustomForm/CustomForm';
import CustomInput from '../../../common/CustomInput/CustomInput';
import { Icon } from '../../../common/Icon/Icon';
import { isValidEmail } from '../../../core/helpers';
import useFetch from '../../../core/hooks/useFetch';
import { useFormInput } from '../../../core/hooks/useFormInput';
import './ApplyForm.css';

function ApplyForm() {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsEmail = searchParams.get('email');
  const { doFetch, loading } = useFetch(`${process.env.REACT_APP_API_URL}/invitation/send`, true);

  const email = useFormInput(
    searchParamsEmail ? decodeURIComponent(searchParamsEmail) : '',
    (value) => isValidEmail(value),
  );

  const handleSubmit = useCallback(async () => {
    const res = await doFetch({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email.value,
      }),
    });

    if (res.status === 'success') {
      searchParams.set('email', res.data?.email);
      setSearchParams(searchParams);
    }
  }, [doFetch, email.value, searchParams, setSearchParams]);

  return (
    <section className="apply-form flex flex--center gap-sm">
      <Link className="modal__close" to="/">
        <Icon.Plus className="icon-large" />
        <span className="sr-only">Home page</span>
      </Link>
      <h1 className="login__title h2">Create an account</h1>
      <CustomForm className="apply-form flex gap-sm" inputs={[email]} onSubmit={handleSubmit}>
        <>
          <CustomInput
            id="apply-email"
            type="text"
            name="email"
            placeholder="Enter your email address"
            label="E-mail address"
            input={email}
            emptyMessage="Please fill in your email"
            invalidMessage="Please fill in a valid email address"
          />
          <CustomButton className="apply-form__cta btn" type="submit" loading={loading}>
            Submit
          </CustomButton>
        </>
      </CustomForm>
      <Link className="link" to="/login">
        Log in
      </Link>
    </section>
  );
}

export default ApplyForm;
