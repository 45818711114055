import { useEffect } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import { useDataContext } from '../../core/context/data.context';
import { useSubscription } from '../../core/context/subscription.context';

function Checkout() {
  const { id } = useParams();
  const { getPaymentSessionStatus, paymentStatus, paymentStatusError } = useSubscription();
  const [searchParams] = useSearchParams();
  const { setPage } = useDataContext();

  useEffect(() => {
    if (!searchParams.get('sessionId') || !searchParams.get('orderref') || !id) return;

    getPaymentSessionStatus(
      searchParams.get('sessionId') || '',
      searchParams.get('sessionResult') || '',
      searchParams.get('orderref') || '',
      id,
      searchParams.get('hashref') || '',
      searchParams.get('discountref') || '',
    );
  }, [getPaymentSessionStatus, searchParams, id]);

  useEffect(() => {
    setPage('');
  }, [setPage]);

  return (
    <section className="user">
      <div className="wrap">
        <h1 className="h2 py-sm">Checkout</h1>
        <div className="user__section">
          <Link className="btn" to="/user">
            Back to Profile
          </Link>
          {!paymentStatus ? null : <h2 className="h4 py-sm">Status: {paymentStatus.message}</h2>}
          {!paymentStatusError ? null : (
            <h2 className="h4 message message--error">Status: {paymentStatusError.message}</h2>
          )}
        </div>
      </div>
    </section>
  );
}

export default Checkout;
