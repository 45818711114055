import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import CustomLink from '../../common/CustomLink/CustomLink';
import { useDataContext } from '../../core/context/data.context';
import { addBackground } from '../../core/helpers';
import useModal from '../../core/hooks/useModal';
import '../Login/Login.css';

function Success() {
  const { modal } = useModal({ keepOpen: true });
  const {
    data: { generalData },
    setPage,
  } = useDataContext();

  useEffect(() => {
    setPage('');
  }, [setPage]);

  return (
    <>
      <Helmet>
        {!generalData?.seo?.title ? null : <title>{generalData?.seo?.title}</title>}
        {!generalData?.seo?.description ? null : (
          <meta name="description" content={generalData?.seo?.description} />
        )}
      </Helmet>
      <div className="login" {...addBackground({ img: generalData.img })} />
      {modal({
        children: (
          <div className="login__container flex flex--center gap-md">
            <h1 className="h2">Account created!</h1>
            <p>You can now enter THNK World and start exploring the available journeys.</p>
            <CustomLink
              link={{
                text: 'Enter THNK world',
                href: '/login',
                hasArrow: true,
                color: 'light',
              }}
            />
          </div>
        ),
        keepMounted: true,
      })}
    </>
  );
}

export default Success;
