import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Outlet } from 'react-router-dom';

import PageLoader from './common/PageLoader/PageLoader';
import { useThemeContext } from './core/context/theme.context';
import { useUserContext } from './core/context/user.context';

function UserApp() {
  const modalContainer: HTMLElement | null = document.getElementById('modal');
  const { setPageColor } = useThemeContext();
  const { user } = useUserContext();

  useEffect(() => {
    setPageColor('red-blue');
  }, [setPageColor]);

  return (
    <>
      {!modalContainer
        ? null
        : createPortal(<PageLoader open={!!user.token && !user.email} />, modalContainer)}
      <Outlet />
    </>
  );
}

export default UserApp;
