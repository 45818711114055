import { useCallback, useRef } from 'react';

import { Icon } from '../../common/Icon/Icon';
import Image from '../../common/Image/Image';
import VideoPlayer from '../../common/VideoPlayer/VideoPlayer';
import { useScreenSizeContext } from '../../core/context/screenSize.context';
import { addBackground } from '../../core/helpers';
import useModal from '../../core/hooks/useModal';
import { IImage } from '../../core/models';
import './Product.css';

interface IProps {
  title: string;
  desc?: string;
  label?: string;
  videoID?: string;
  video?: string;
  videoLabel?: string;
  images: IImage[];
  img?: IImage;
}

function Product({ title, desc, label, videoID, images, img, video, videoLabel }: IProps) {
  const { isMobile } = useScreenSizeContext();
  const { modal, open, close } = useModal({});
  const btnRef = useRef(null);

  const openModal = useCallback(() => {
    if (!btnRef.current) return;
    open(btnRef.current);
  }, [open]);

  return (
    <section className="product" {...addBackground({ img, gradient: 'red2' })}>
      <div className="wrap wrap--lg">
        <div className="product__container">
          {!isMobile && !images.length ? null : (
            <div className="product__images">
              <div className="product__images-container">
                {images.map((image) => (
                  <div className="product__image-container" key={image._id}>
                    <Image className="product__image" img={image} size="small" />
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="product__content">
            {!label ? null : <div className="product__label overline">{label}</div>}
            <h2 className="product__title h2">{title}</h2>
            {!desc ? null : <p className="product__desc">{desc}</p>}
            {!video || !videoLabel ? null : (
              <button
                className="product__cta btn"
                type="button"
                id={videoID}
                onClick={openModal}
                ref={btnRef}
              >
                {videoLabel}
              </button>
            )}
          </div>
        </div>
      </div>
      {modal({
        children: (
          <div className="product__video">
            <button className="modal__close" onClick={close} type="button">
              <Icon.Plus className="icon-large" />
              <span className="sr-only">Close modal</span>
            </button>
            <VideoPlayer src={video} />
          </div>
        ),
      })}
    </section>
  );
}

export default Product;
