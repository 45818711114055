import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import './Loader.css';

interface IProps {
  open: boolean;
}

function Loader({ open }: IProps) {
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={open}
      timeout={750}
      classNames="simple-loader"
      unmountOnExit
    >
      <div ref={nodeRef} className="simple-loader">
        <div className="simple-loader__symbol" />
      </div>
    </CSSTransition>
  );
}

export default Loader;
