export const getPriceString = (price: number, discount?: number): string => {
  const totalPrice = price * (1 - (discount || 0) / 100);

  return `€ ${totalPrice.toFixed(2)}`;
};

export const getDiscountString = (price: number, discount?: number): string => {
  const totalPrice = price * ((discount || 0) / 100);

  return `€ ${totalPrice.toFixed(2)}`;
};
