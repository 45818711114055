import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet-async';

import HomeBanner from '../../Components/HomeBanner/HomeBanner';
import PageBuilder from '../../Components/PageBuilder/PageBuilder';
import PageLoader from '../../common/PageLoader/PageLoader';
import { useDataContext } from '../../core/context/data.context';
import PageNotFound from '../PageNotFound/PageNotFound';

function Home() {
  const modalContainer: HTMLElement | null = document.getElementById('modal');
  const {
    data: { 'world-home-page': page, generalData },
    setPage,
  } = useDataContext();
  const body = useMemo(() => page || null, [page]);

  useEffect(() => {
    setPage('world-home-page');
  }, [setPage]);

  return (
    <>
      <Helmet>
        {!page?.seo?.title && !generalData?.seo?.title ? null : (
          <title>{page?.seo?.title || generalData?.seo?.title}</title>
        )}
        {!page?.seo?.canonical ? null : <link rel="canonical" href={page?.seo?.canonical} />}
        {!page?.seo?.description && !generalData?.seo?.description ? null : (
          <meta
            name="description"
            content={page?.seo?.description || generalData?.seo?.description}
          />
        )}
      </Helmet>
      {!modalContainer ? null : createPortal(<PageLoader open={!body} />, modalContainer)}
      {!body ? null : body === 'Page not found' ? (
        <PageNotFound />
      ) : (
        <>
          {!body.banner ? null : <HomeBanner {...body.banner} />}
          {!body.pageBuilder ? null : <PageBuilder data={body.pageBuilder} slug="home-page" />}
        </>
      )}
    </>
  );
}

export default Home;
