import { Outlet } from 'react-router-dom';

import Header from './Components/Header/Header';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import './style/base.css';
import './style/buttons.css';
import './style/forms.css';
import './style/inputs.css';
import './style/layout.css';
import './style/loader.css';
import './style/reset.css';
import './style/table.css';
import './style/typography.css';
import './style/variables.css';

function App() {
  return (
    <div className="app">
      <ScrollToTop />
      <Header />
      <main>
        <Outlet />
      </main>
    </div>
  );
}

export default App;
