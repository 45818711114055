import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import Footer from './Components/Footer/Footer';
import { useThemeContext } from './core/context/theme.context';

function PagesApp() {
  const { setPageColor } = useThemeContext();

  useEffect(() => {
    setPageColor('blue-orange');
  }, [setPageColor]);

  return (
    <>
      <Outlet />
      <Footer />
    </>
  );
}

export default PagesApp;
