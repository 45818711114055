import { Route, Routes } from 'react-router-dom';

import App from './App';
import Legal from './Components/Legal/Legal';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import EmailConfirm from './Components/User/EmailConfirm/EmailConfirm';
import Apply from './Pages/Apply/Apply';
import Checkout from './Pages/Checkout/Checkout';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import Home from './Pages/Home/Home';
import Login from './Pages/Login/Login';
import Page from './Pages/Page/Page';
import PageNotFound from './Pages/PageNotFound/PageNotFound';
import Profile from './Pages/Profile/Profile';
import Register from './Pages/Register/Register';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import Subscription from './Pages/Subscription/Subscription';
import Subscriptions from './Pages/Subscriptions/Subscriptions';
import Success from './Pages/Success/Success';
import UserOverview from './Pages/UserOverview/UserOverview';
import PagesApp from './PagesApp';
import PlaygroundApp from './PlaygroundApp';
import UserApp from './UserApp';
import { useUserContext } from './core/context/user.context';
import { toBool } from './core/helpers';

function AppRoutes() {
  const { user } = useUserContext();

  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route element={<UserApp />}>
          <Route path="login" element={<Login />} />
          {toBool(process.env.REACT_APP_HIDE_REGISTRATION) ? null : (
            <Route path="apply" element={<Apply />} />
          )}
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="resetpassword" element={<ResetPassword />} />
          <Route path="success" element={<Success />} />
          <Route path="email-confirm/:hash" element={<EmailConfirm />} />
        </Route>
        <Route element={<ProtectedRoute user={user} />}>
          <Route path="user" element={<PlaygroundApp />}>
            <Route index element={<UserOverview />} />
            <Route path="subscriptions" element={<Subscriptions />} />
            <Route path="profile" element={<Profile />} />
          </Route>
          <Route element={<PlaygroundApp />}>
            <Route path="subscription/:id" element={<Subscription />} />
            <Route path=":id/checkout" element={<Checkout />} />
          </Route>
        </Route>
        <Route element={<PagesApp />}>
          <Route index element={<Home />} />
          <Route path="terms-of-use" element={<Legal type="termsAndConditions" />} />
          <Route path="privacy-policy" element={<Legal type="privacyPolicy" />} />
          <Route path=":slug" element={<Page />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default AppRoutes;
