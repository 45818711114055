/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from '../../assets/logo-world.svg';
import { Icon } from '../../common/Icon/Icon';
import Image from '../../common/Image/Image';
import { useDataContext } from '../../core/context/data.context';
import { useScreenSizeContext } from '../../core/context/screenSize.context';
import { useThemeContext } from '../../core/context/theme.context';
import { useUserContext } from '../../core/context/user.context';
import useModal from '../../core/hooks/useModal';
import UserDropdown from '../User/UserDropdown/UserDropdown';
import './Header.css';

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    data: {
      generalData: { navigation, logo },
    },
  } = useDataContext();
  const { isScrolled, setIsScrolled } = useThemeContext();
  const { user } = useUserContext();
  const { isMobile } = useScreenSizeContext();
  const burgerRef = useRef(null);
  const { modal, open, isOpen, close } = useModal({});
  const isUserPage =
    location.pathname.includes('/user') || location.pathname.includes('/subscription');
  const isUserSubPage =
    (isUserPage && location.pathname.includes('/profile')) ||
    location.pathname.includes('/subscriptions') ||
    location.pathname.includes('/subscription');
  const fromPG = document.referrer.includes('/pg');

  const openNav = useCallback(() => {
    if (!burgerRef.current) return;
    open(burgerRef.current);
  }, [open]);

  const navList = useMemo(
    () =>
      !navigation?.navItems?.length ? null : (
        <ul className="header__nav-list">
          {navigation.navItems.map((link: any) => (
            <li className="header__nav-item" key={link.label}>
              <NavLink
                className={({ isActive }) => `header__nav-link link${isActive ? ' active' : ''}`}
                to={link.href}
                replace={isOpen}
              >
                {link.label}
              </NavLink>
            </li>
          ))}
        </ul>
      ),
    [navigation.navItems, isOpen],
  );

  useEffect(() => {
    if (document.body.style.inset) {
      document.body.style.inset = '0';
      setIsScrolled(false);
    }
    close();
  }, [isMobile, close, location.pathname, setIsScrolled]);

  return (
    <header className={`header${isScrolled ? ' scrolled blur' : ''}`}>
      <div className="wrap wrap--xl">
        <nav className="header__nav">
          <Link className="header__logo" to="/">
            <span className="sr-only">Home page</span>
            {logo?.img ? (
              <Image className="header__logo-img" img={logo.img} size="small" />
            ) : (
              <Logo className="header__logo-img" />
            )}
          </Link>
          {isUserPage ? (
            <>
              {fromPG ? (
                <button
                  className="header__back link"
                  type="button"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <Icon.Arrow className="icon flip-x" />
                  Back
                </button>
              ) : (
                <Link className="header__back link" to={isUserSubPage ? '/user' : '/'}>
                  <Icon.Arrow className="icon flip-x" />
                  {isUserSubPage ? 'Profile' : 'Back'}
                </Link>
              )}
              <UserDropdown />
            </>
          ) : (
            <>
              {!navigation?.navItems?.length ? null : isMobile ? (
                <button
                  className="header__burger link"
                  type="button"
                  onClick={openNav}
                  ref={burgerRef}
                >
                  Menu
                </button>
              ) : (
                navList
              )}
              {!user.token ? null : <UserDropdown />}
            </>
          )}
        </nav>
      </div>
      {modal({
        children: (
          <div className="header__mobile-nav">
            <button
              className="header__close"
              type="button"
              onClick={() => {
                navigate(-1);
              }}
            >
              <span className="sr-only">Close menu</span>
              <Icon.Plus className="icon rotate-45" />
            </button>
            <Link className="header__logo header__logo--mobile" to="/" replace>
              <span className="sr-only">Home page</span>
              {logo?.img ? (
                <Image className="header__logo-symbol" img={logo.img} />
              ) : (
                <Logo className="header__logo-symbol" />
              )}
            </Link>
            {navList}
          </div>
        ),
        side: 'right',
        preventNavigate: true,
      })}
    </header>
  );
}

export default Header;
