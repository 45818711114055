/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef, useState } from 'react';

import { useAlert } from '../context/alert.context';
// eslint-disable-next-line import/no-cycle
import { useUserContext } from '../context/user.context';

const useFetch = (url: string, alert?: boolean) => {
  const user = useUserContext();
  const { setAlert } = useAlert();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>('');
  const [result, setResult] = useState<any>(null);
  const abortController = useRef<any>(null);

  const doFetch = useCallback(
    async (
      options?: RequestInit & {
        newURL?: string;
      },
    ) => {
      setLoading(true);
      setError('');
      if (abortController.current) abortController.current.abort();

      try {
        abortController.current = new AbortController();
        const res = await fetch(options?.newURL || url, {
          signal: abortController.current.signal,
          ...options,
        });

        if (res.headers.get('Content-Type') === 'application/pdf') {
          if (!res.ok) {
            throw new Error('Download failed!');
          }
          const blob = await res.blob();
          setResult(blob);
          return blob;
        }

        const resData = await res.json();
        if (res.status === 403) {
          user?.logout();
        }

        if (
          !resData ||
          resData.status === 'error' ||
          resData.status === 'fail' ||
          resData.statusCode === 404
        ) {
          throw new Error(resData.message);
        }
        setError('');
        setResult({ ...resData });
        setLoading(false);
        if (alert) {
          setAlert({
            message: resData.message,
            status: 'success',
          });
        }
        return resData;
      } catch (err: any) {
        if (err.name !== 'AbortError') {
          setError(err);
          setResult(null);
          setLoading(false);
          setAlert({
            message: err.message,
            status: 'error',
          });
        } else {
          return 'aborted';
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [alert, user?.logout, setAlert, url],
  );

  return { loading, error, result, doFetch, abortController };
};

export default useFetch;
