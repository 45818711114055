/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';

import useFetch from '../hooks/useFetch';
import { CurrentUser, ISubscription } from '../models';
import { useAlert } from './alert.context';
import { useUserContext } from './user.context';

interface ISub {
  subscriptions: ISubscription[];
  doSubFetch: () => void;
  addPaymentMethod: (id: string, method: string, code?: string | null) => void;
  cancelSubscription: (id: string) => void;
  renewSubscription: (id: string) => void;
  updateSubscriptionResult: any;
  updateSubscriptionLoading: boolean;
  getPaymentSessionStatus: (
    sessionId: string,
    sessionResult: string,
    orderRef: string,
    id: string,
    hashref: string,
    discount?: string,
  ) => void;
  fetchSubscriptions: () => void;
  paymentStatus: { message: string; status: string };
  paymentStatusError: any;
}

const SubscriptionContext = createContext<ISub | null>(null);

export const useSubscription = () => useContext(SubscriptionContext) as ISub;

interface IProps {
  children: React.ReactNode;
}

export function SubscriptionContextProvider({ children }: IProps) {
  const { setAlert } = useAlert();
  const { user, setUser } = useUserContext();

  const {
    result: subscriptions,
    doFetch: doSubFetch,
    error: subError,
  } = useFetch(`${process.env.REACT_APP_API_URL}/user/subscriptions`);

  const { doFetch: doPaidSubFetch } = useFetch(
    `${process.env.REACT_APP_API_URL}/subscriptions/paid`,
  );

  const {
    result: paymentStatus,
    doFetch: doPaymentStatusFetch,
    error: paymentStatusError,
  } = useFetch('');

  const { doFetch: doAddPaymentMethodFetch } = useFetch('');
  const {
    result: updateSubscriptionResult,
    doFetch: doUpdateSubscriptionFetch,
    loading: updateSubscriptionLoading,
  } = useFetch('', true);

  useEffect(() => {
    if (!subError) return;
    setAlert({
      message: subError.message,
      status: 'error',
    });
  }, [setAlert, subError]);

  const addPaymentMethod = useCallback(
    async (id: string, method: string, code?: string | null) => {
      const res = await doAddPaymentMethodFetch({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          clientBaseUrl: `${process.env.REACT_APP_BASE_URL}/${id}`,
          paymentMethod: method,
          discountCode: code || undefined,
        }),
        newURL: `${process.env.REACT_APP_API_URL}/user/subscriptions/paid/${id}/add-method`,
      });

      if (res?.status !== 'success') {
        return;
      }

      window.location.href = res.data.hostedCheckoutUrl;
    },
    [doAddPaymentMethodFetch, user.token],
  );

  const fetchSubscriptions = useCallback(() => {
    (async () => {
      const res = await Promise.all([
        doSubFetch({
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }),
        doPaidSubFetch({
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }),
      ]);

      if (!res || res.includes('aborted')) return;

      if (
        res[0]?.data.find((result: ISubscription) => result.subscription[0]?.paid && result.active)
      ) {
        setUser((prevState: CurrentUser[]) => ({
          ...prevState,
          subscriptions: res.reduce(
            (acc, result) =>
              result?.data
                ? [...acc, ...result.data.filter((el: ISubscription) => el.subscription[0]?.paid)]
                : null,
            [],
          ),
        }));
      } else {
        setUser((prevState: CurrentUser[]) => ({
          ...prevState,
          subscriptions: res.reduce(
            (acc, result) => (result?.data ? [...acc, ...result.data] : null),
            [],
          ),
        }));
      }
    })();
  }, [doPaidSubFetch, doSubFetch, setUser, user.token]);

  const getPaymentSessionStatus = useCallback(
    (
      sessionId: string,
      sessionResult: string,
      orderRef: string,
      id: string,
      hashref: string,
      discount?: string,
    ) => {
      doPaymentStatusFetch({
        newURL: `${process.env.REACT_APP_API_URL}/user/subscriptions/${id}/sessions/status`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          sessionId,
          sessionResult,
          orderRef,
          discountCode: discount || 'none',
          hashRef: hashref,
        }),
      });
    },
    [doPaymentStatusFetch, user.token],
  );

  const cancelSubscription = useCallback(
    (id: string) => {
      doUpdateSubscriptionFetch({
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        newURL: `${process.env.REACT_APP_API_URL}/user/subscriptions/${id}/cancel`,
      });
    },
    [doUpdateSubscriptionFetch, user.token],
  );

  const renewSubscription = useCallback(
    (id: string) => {
      doUpdateSubscriptionFetch({
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        newURL: `${process.env.REACT_APP_API_URL}/user/subscriptions/${id}/renew`,
      });
    },
    [doUpdateSubscriptionFetch, user.token],
  );

  const value = useMemo(
    () => ({
      subscriptions,
      doSubFetch,
      addPaymentMethod,
      updateSubscriptionResult,
      updateSubscriptionLoading,
      cancelSubscription,
      renewSubscription,
      fetchSubscriptions,
      getPaymentSessionStatus,
      paymentStatus,
      paymentStatusError,
    }),
    [
      subscriptions,
      doSubFetch,
      addPaymentMethod,
      updateSubscriptionResult,
      updateSubscriptionLoading,
      cancelSubscription,
      renewSubscription,
      fetchSubscriptions,
      getPaymentSessionStatus,
      paymentStatus,
      paymentStatusError,
    ],
  );

  return <SubscriptionContext.Provider value={value}>{children}</SubscriptionContext.Provider>;
}
