import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import DiscountField from '../../Components/DiscountField/DiscountField';
import CustomButton from '../../common/CustomButton/CustomButton';
import CustomCheckbox from '../../common/CustomCheckbox/CustomCheckbox';
import CustomForm from '../../common/CustomForm/CustomForm';
import CustomRadio from '../../common/CustomRadio/CustomRadio';
import { Icon } from '../../common/Icon/Icon';
import { useSubscription } from '../../core/context/subscription.context';
import { useUserContext } from '../../core/context/user.context';
import useFetch from '../../core/hooks/useFetch';
import { useFormCheckbox } from '../../core/hooks/useFormCheckbox';
import { useFormRadio } from '../../core/hooks/useFormRadio';
import { IDiscount } from '../../core/models/subscription';
import { getPriceString } from '../../core/service';

function Subscription() {
  const { id } = useParams();
  const { user } = useUserContext();
  const { addPaymentMethod, fetchSubscriptions } = useSubscription();
  const [discount, setDiscount] = useState<IDiscount | null>(null);
  const { doFetch: doApplyDiscountFetch } = useFetch('');
  const { result: removeDiscountResult, doFetch: doRemoveDiscountFetch } = useFetch(
    `${process.env.REACT_APP_API_URL}/user/discounts/remove`,
  );
  const {
    result: methods,
    doFetch,
    loading,
  } = useFetch(`${process.env.REACT_APP_API_URL}/subscription/available-methods/${id}`);
  const paymentMethods = useMemo(
    () =>
      methods?.data.paymentMethods?.map((method: { name: string; type: string }) => ({
        value: method.type,
        label: method.name,
        icon: (
          <img
            src={`${process.env.REACT_APP_ADYEN_IMG_URL?.replace('{type}', method.type === 'scheme' ? 'card' : method.type)}`}
            alt={method.name}
          />
        ),
      })) || [],
    [methods?.data.paymentMethods],
  );

  const termsCheckbox = useFormCheckbox(false, (value) => value);
  const paymentMethod = useFormRadio(paymentMethods[0]?.value || '', (value) => !!value);

  useEffect(() => {
    doFetch({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
  }, [doFetch, user]);

  useEffect(() => {
    if (!user.email) return;
    fetchSubscriptions();
  }, [user.email, fetchSubscriptions]);

  useEffect(() => {
    if (!removeDiscountResult) return;
    fetchSubscriptions();
  }, [fetchSubscriptions, removeDiscountResult]);

  const subscription = useMemo(
    () => user.subscriptions?.find((sub) => sub.subscription[0]?._id === id),
    [id, user],
  );

  useEffect(() => {
    setDiscount(subscription?.discountCode?.[0] || null);
  }, [subscription]);

  const buySubscription = useCallback(() => {
    if (!id) return;
    addPaymentMethod(id, paymentMethod.value, discount?.code);
  }, [addPaymentMethod, discount?.code, id, paymentMethod.value]);

  const applyDiscount = useCallback(
    async (value: string) => {
      const res = await doApplyDiscountFetch({
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        newURL: `${process.env.REACT_APP_API_URL}/discount/code/${value}`,
      });

      if (res?.data) {
        setDiscount(res.data);
      }
    },
    [doApplyDiscountFetch, setDiscount, user.token],
  );

  const removeDiscount = useCallback(() => {
    if (subscription?.discountCode?.[0]) {
      doRemoveDiscountFetch({
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          code: subscription.discountCode?.[0].code,
          subscription: subscription.subscription[0]._id,
        }),
      });
    } else {
      setDiscount(null);
    }
  }, [doRemoveDiscountFetch, subscription?.discountCode, subscription?.subscription, user.token]);

  return (
    <section className="user">
      <div className="wrap wrap--md flex gap-md">
        <div className="user__header">
          <h1 className="user__title h3">
            {subscription?.status?.message === 'Active'
              ? 'Change payment method'
              : 'Buy subscription'}
          </h1>
        </div>
        {!subscription ? (
          <span className="loader" />
        ) : (
          <CustomForm
            className="flex gap-md"
            onSubmit={buySubscription}
            inputs={[paymentMethod, termsCheckbox]}
          >
            {subscription?.status?.message === 'Active' ? null : (
              <div>
                <div className="h4">Summary</div>
                <div className="user__section">
                  <div className="subscription__header">
                    <div className="subscription__left">
                      <h3 className="subscription__title h4">
                        {subscription?.subscription[0].name}
                      </h3>
                      <p className="subscription__info">
                        {subscription?.subscription[0].description}
                      </p>
                    </div>
                    <div className="subscription__right">
                      <div>
                        {getPriceString(subscription.subscription[0].paid_details.amount)} p/m
                      </div>
                    </div>
                  </div>
                  <div className="subscription__details">
                    <DiscountField
                      discount={discount}
                      subscription={subscription}
                      remove={removeDiscount}
                      apply={applyDiscount}
                    />
                  </div>
                  {!discount ? null : (
                    <div className="subscription__details">
                      <div className="discount subscription__header">
                        <div className="subscription__left">Due now</div>
                        <div className="subscription__right">
                          {' '}
                          {getPriceString(
                            subscription.subscription[0].paid_details.amount,
                            discount?.discount_p,
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {loading || !methods ? null : !paymentMethods.length ? (
              <div className="message message--error">No payment methods found</div>
            ) : (
              <>
                <CustomRadio
                  input={paymentMethod}
                  buttons={paymentMethods}
                  label="Select payment method"
                  name="payment-method"
                />
                <CustomCheckbox
                  id="terms-checkbox"
                  name="terms-checkbox"
                  input={termsCheckbox}
                  label={
                    <>
                      I agree to the THNK World{' '}
                      <Link to="/terms-of-use" target="_blank">
                        subscription terms of use
                      </Link>
                    </>
                  }
                  invalidMessage="You have to accept the privacy policy and terms of use"
                />
              </>
            )}
            <CustomButton type="submit" className="btn" disabled={!paymentMethod.value}>
              {subscription?.status?.message === 'Active'
                ? `Change payment method (€ ${paymentMethod.value === 'ideal' ? '0.01' : '0.00'})`
                : `Continue to checkout (${
                    paymentMethod.value === 'ideal' &&
                    subscription.subscription[0].paid_details.amount *
                      (1 - (discount?.discount_p || 0) / 100) ===
                      0
                      ? getPriceString(0.01)
                      : getPriceString(
                          subscription.subscription[0].paid_details.amount,
                          discount?.discount_p,
                        )
                  })`}
              <Icon.Arrow className="icon" />
            </CustomButton>
          </CustomForm>
        )}
      </div>
    </section>
  );
}

export default Subscription;
