import { createContext, useContext, useEffect, useMemo, useState } from 'react';

interface Props {
  children: JSX.Element;
}

export type TColor = 'red' | 'green' | 'blue' | 'blue-orange' | 'red-blue';

interface Theme {
  isScrolled: boolean;
  setIsScrolled: React.Dispatch<React.SetStateAction<boolean>>;
  setPageColor: React.Dispatch<React.SetStateAction<TColor>>;
}

const ThemeContext = createContext<Theme | null>(null);

export const useThemeContext = () => useContext(ThemeContext) as Theme;

export function ThemeContextProvider({ children }: Props) {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [pageColor, setPageColor] = useState<TColor>('blue-orange');

  useEffect(() => {
    const handleScroll = () => {
      if (document.body.style.overflow === 'clip') return;
      setIsScrolled((document.documentElement.scrollTop || document.body.scrollTop) > 30);
    };
    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const root = document.querySelector<HTMLDivElement>('#root');
    if (!root) return;
    root.style.setProperty(
      '--bg-gradient',
      pageColor === 'red'
        ? 'var(--red-gradient)'
        : pageColor === 'green'
          ? 'var(--green-gradient)'
          : pageColor === 'blue'
            ? 'var(--blue5-gradient)'
            : pageColor === 'red-blue'
              ? 'var(--red-blue-gradient)'
              : pageColor === 'blue-orange'
                ? 'var(--blue-orange-gradient)'
                : '',
    );
  }, [pageColor]);

  const value: Theme = useMemo(
    () => ({
      isScrolled,
      setIsScrolled,
      setPageColor,
    }),
    [isScrolled, setIsScrolled],
  );
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
}
