import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import SubscriptionSimple from '../../Components/User/SubscriptionSimple/SubscriptionSimple';
import { Icon } from '../../common/Icon/Icon';
import Loader from '../../common/Loader/Loader';
import { useDataContext } from '../../core/context/data.context';
import { useSubscription } from '../../core/context/subscription.context';
import { useUserContext } from '../../core/context/user.context';
import '../../style/user.css';

function UserOverview() {
  const { user } = useUserContext();
  const { fetchSubscriptions, updateSubscriptionResult, updateSubscriptionLoading } =
    useSubscription();
  const { setPage } = useDataContext();

  useEffect(() => {
    if (!user.email) return;

    fetchSubscriptions();
  }, [user.email, fetchSubscriptions]);

  useEffect(() => {
    setPage('');
  }, [setPage]);

  useEffect(() => {
    if (!updateSubscriptionResult) return;

    fetchSubscriptions();
  }, [updateSubscriptionResult, fetchSubscriptions]);

  return (
    <section className="user">
      <div className="wrap wrap--md">
        <div className="user__header">
          <h1 className="user__title h3">Profile</h1>
          <Link className="btn" to="profile">
            Manage profile
          </Link>
        </div>
        <div className="user__section">
          <div className="user__section-container">
            <div className="user__section-left">
              <h2 className="h4">
                {user.firstname} {user.lastname}
              </h2>
              <div className="user__email inline-link">{user.email}</div>
              <p className="user__bio truncate l-2">{user.antiProfileText}</p>
            </div>
            {!user.antiProfilePic?.medium ? (
              <Icon.User className="user__img" />
            ) : (
              <img className="user__img" src={user.antiProfilePic?.medium} alt="profile" />
            )}
          </div>
        </div>
        <div className="user__subscriptions">
          <div className="user__header">
            <h2 className="user__title h3">Subscriptions</h2>
            {!user.subscriptions?.length ? null : (
              <Link className="btn" to="subscriptions">
                Manage subscriptions
              </Link>
            )}
          </div>
          <div>
            {!user.subscriptions?.length ? (
              <h3 className="subscription__info h4">You don&apos;t have any subscriptions</h3>
            ) : (
              user.subscriptions.map((subscription) =>
                !subscription.subscription.length ? null : (
                  <SubscriptionSimple
                    subscription={subscription}
                    key={subscription.subscription[0]?.name}
                  />
                ),
              )
            )}
          </div>
        </div>
      </div>
      <Loader open={updateSubscriptionLoading} />
    </section>
  );
}

export default UserOverview;
