import React, { useCallback, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

import { Icon } from '../../common/Icon/Icon';
import { addBackground } from '../../core/helpers';
import useModal from '../../core/hooks/useModal';
import { IImage } from '../../core/models';
import HubSpotForm from '../HubSpotForm/HubSpotForm';
import './Mission.css';

interface IProps {
  title: string;
  desc?: string;
  form: {
    portalId?: string;
    formId?: string;
    region?: string;
    id?: string;
    btnLabel?: string;
  };
  usps: {
    title: string;
  }[];
  slug: string;
  img: IImage;
}

function Mission({ title, desc, form, slug, usps, img }: IProps) {
  const { modal, open, close } = useModal({});
  const btnRef = useRef(null);

  const openModal = useCallback(() => {
    if (!btnRef.current) return;
    open(btnRef.current);
  }, [open]);

  return (
    <section className="mission" {...addBackground({ img, gradient: 'blue4' })}>
      <div className="wrap wrap--lg">
        <h2 className="mission__title h2">
          <ReactMarkdown
            components={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              p: React.Fragment as any,
              em: 'strong',
            }}
          >
            {title}
          </ReactMarkdown>
        </h2>
        <div className="mission__container">
          <div className="mission__content">
            {!desc ? null : <p className="mission__desc">{desc}</p>}
            {!form.btnLabel || !form.portalId || !form.formId || !form.region || !form.id ? null : (
              <>
                <button
                  className="mission__cta btn"
                  type="button"
                  onClick={openModal}
                  ref={btnRef}
                  id={`${slug}-${form.formId}`}
                >
                  {form.btnLabel}
                </button>
                {modal({
                  children: (
                    <>
                      <button className="modal__close" onClick={close} type="button">
                        <Icon.Plus className="icon-large" />
                        <span className="sr-only">Close modal</span>
                      </button>
                      <HubSpotForm
                        portalId={form.portalId}
                        formId={form.formId}
                        region={form.region}
                        id={form.id}
                      />
                    </>
                  ),
                  keepMounted: true,
                })}
              </>
            )}
          </div>
          <div className="mission__usps">
            {usps.map((usp, i) => (
              <div className="mission__usp h4" key={usp.title}>
                <span className="mission__usp-numb">{i + 1}</span>
                {usp.title}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Mission;
