import { useCallback, useRef, useState } from 'react';

import Modal from '../../common/Modal/Modal';

interface IProps {
  children: JSX.Element;
  side?: 'center' | 'right' | 'bottom';
  keepMounted?: boolean;
  preventNavigate?: boolean;
  size?: ' lg';
}

const useModal = ({ keepOpen }: { keepOpen?: boolean }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openBtnRef = useRef<{ element: HTMLButtonElement | null }>({ element: null });
  const closeCallback = useRef({
    f: () => {},
  });

  const open = useCallback((ref: HTMLButtonElement) => {
    openBtnRef.current.element = ref;
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const modal = useCallback(
    ({ children, side, keepMounted, preventNavigate, size }: IProps) => (
      <Modal
        isOpen={keepOpen || isOpen}
        close={close}
        side={side}
        openBtn={openBtnRef.current.element}
        closeCallback={closeCallback}
        keepMounted={keepMounted}
        preventNavigate={preventNavigate}
        keepOpen={keepOpen}
        size={size}
      >
        {children}
      </Modal>
    ),

    [close, isOpen, keepOpen],
  );

  return { isOpen, open, close, modal, openBtnRef, closeCallback };
};

export default useModal;
