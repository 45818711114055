import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from '../../assets/logo-world.svg';
import Image from '../../common/Image/Image';
import { useDataContext } from '../../core/context/data.context';
import { IFooter, IImage } from '../../core/models';
import NewsLetterInline from '../NewsLetterInline/NewsLetterInline';
import './Footer.css';

function Footer() {
  const {
    data: {
      generalData: { footer, logo },
    },
  }: { data: { generalData: { footer: IFooter; logo: { img: IImage } } } } = useDataContext();

  if (!footer) return null;

  return (
    <footer className="footer">
      <div className="wrap wrap--lg">
        <div className="footer__container">
          <div className="footer__left">
            <NewsLetterInline
              portalId={footer?.newsletter?.portalId}
              formId={footer?.newsletter?.formId}
              region={footer?.newsletter?.region}
              id={footer?.newsletter?.id}
            />
            <nav className="footer__nav">
              <ul className="footer__list">
                {footer.navigation?.map((el) => (
                  <li className="footer__item" key={el.label}>
                    <Link className="footer__link link" to={el.href}>
                      {el.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div className="footer__right">
            <Link className="footer__logo" to="/" id="footer-logo">
              <span className="sr-only">Home page</span>
              {logo?.img ? (
                <Image className="footer__logo-img" img={logo.img} size="small" />
              ) : (
                <Logo className="footer__logo-img" />
              )}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
