/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import useFetch from '../hooks/useFetch';

interface IProps {
  children: JSX.Element;
}

interface IDataContext {
  data: any;
  setPage: React.Dispatch<React.SetStateAction<string>>;
  page: string;
  error: any;
}

const PAGE_URL = `${process.env.REACT_APP_API_URL}/page`;

const DataContext = createContext<any>(null);

export const useDataContext = () => useContext(DataContext) as IDataContext;

export function DataContextProvider({ children }: IProps) {
  const { result: generalData, doFetch: fetchGeneralData } = useFetch(
    `${PAGE_URL}?slug=world-general-data&populate=img&world_page=true`,
  );
  const [page, setPage] = useState('');
  const { doFetch: fetchPageData, error } = useFetch(
    `${PAGE_URL}?slug=${page}&world_page=true&populate=user_id&populate=img&populate=article&populate=profile_pic&populate=authors&populate=tags&populate=case&populate=images`,
  );

  const [data, setData] = useState<any>({
    generalData: {
      logo: { img: '' },
      navigation: {},
      legal: {},
      footer: {},
      contact: {},
    },
  });

  useEffect(() => {
    fetchGeneralData();
  }, [fetchGeneralData]);

  useEffect(() => {
    if (!generalData) return;
    setData((prevState: any) => ({
      ...prevState,
      generalData: generalData.data.body,
    }));
  }, [generalData]);

  useEffect(() => {
    if (data[page] || !page) return;
    const fetchData = async () => {
      const res = await fetchPageData();
      if (res === 'aborted') return;
      if (res?.status === 'success') {
        setData((prevState: any) => ({
          ...prevState,
          [page]: res.data.body,
        }));
      } else {
        setData((prevState: any) => ({
          ...prevState,
          [page]: 'Page not found',
        }));
      }
    };

    fetchData();
  }, [data, fetchPageData, page]);

  const value = useMemo(
    () => ({
      data,
      setPage,
      page,
      error,
    }),
    [data, error, page],
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}
