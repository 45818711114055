import ReactMarkdown from 'react-markdown';

import { TBackground, addBackground } from '../../core/helpers';
import './Title.css';

interface IProps {
  label?: string;
  title: string;
  background?: TBackground;
}

function Title({ label, title, background }: IProps) {
  return (
    <section
      className={`title${background ? ' section-bg' : ' section'}`}
      {...addBackground({ img: undefined, gradient: background })}
    >
      <div className="wrap wrap--lg">
        <div className="flex gap-md">
          {!label ? null : <div className="title__label h3">{label}</div>}
          <h2 className="title__title h2">
            <ReactMarkdown
              components={{
                em: 'span',
              }}
            >
              {title}
            </ReactMarkdown>
          </h2>
        </div>
      </div>
    </section>
  );
}

export default Title;
