import ReactMarkdown from 'react-markdown';

import './TextBlock.css';

interface Props {
  content: string;
  align?: string;
}

function TextBlock({ content, align }: Props) {
  return (
    <div className={`text-block${!align ? '' : ` text-block--${align}`}`}>
      <div className="wrap">
        <ReactMarkdown className="markdown">{content}</ReactMarkdown>
      </div>
    </div>
  );
}

export default TextBlock;
